<template>
	<div class="modal-empty-suitcase">
		<div class="bg" v-tap="close"></div>
		<div class="content">
			<div class="title">Docu-découvertes</div>
			<div class="question">
				<p>As-tu vu ce badge en Ontario?</p>
				<img v-if="modal?.icon" class="icon" :src="modal?.icon"></img>
				<p>C'est un docu-découvertes: une valise remplie d'informations précieuses pour notre voyage. Garde l'œil ouvert, tu en apercevras bientôt d'autres ailleurs au Canada. Une fois trouvés, tes docu-découvertes apparaîtront ici.</p>
			</div>
			<div class="answers">
				<ui-button class="ui-btn" color="green" block="true" v-tap="leave">Fermer</ui-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		modal: {
			type: Object
		}
	},
	methods: {
		leave() {
			this.close()
		},
		close() {
			this.$emit('close')
		},
	},
}
</script>

<style lang="stylus" scoped>

.modal-empty-suitcase
	absolute 0
	flex center
	.bg
		absolute 0
	> .content
		width calc(100vw - 32px)
		max-width 500px
		padding 32px
		flex center column 
		gap 16px
		border-radius 8px
		color #fff
		font-size 2rem
		text-wrap balance
		text-align center
		.title
			width 100%
			margin 0 0 16px 0
			font-size 3.2rem
			border-bottom 3px solid blue
			text-transform uppercase
		.question
			width 100%
			flex center column
			gap 16px
			> p
				width 100%
				strong
					font-weight 500
			.icon	
				width 104px
				height 104px
		.answers
			width 100%
			flex center
			gap 16px
			.ui-btn
				width fit-content
				padding 0 32px
				text-transform uppercase

@media (max-width 300px)
	.modal-empty-suitcase
		> .content
			font-size 1.8rem
			padding 0 16px
		
</style>