<template>
	<div v-if="audioUrl" class="ui-audio-player-button">
		<audio ref="audio">
			<source :src="url" type="audio/mpeg">
		</audio>
		<ui-button :loading="duration === 0" shape="round" :color="playing ? 'dark-yellow' : 'yellow'" :disabled="settings?.voices?.muted" aria-label="Narration" @click="play">
			<ui-icon name="voice-button" :size="24" :class="{ 'icon-playing': playing }" />
		</ui-button>
	</div>
</template>

<script>
export default {
	data() {
		return {
			audioFile: null,
			url: '',
			playing: false,
			duration: 0,
		}
	},
	props: {
		audioUrl: { type: String, required: true },
		autoplay: { type: Boolean, default: true },
		autoplayDelay: { type: Number, default: 500 },
	},
	computed: {
		...mapState(useAppStore, [ 'settings' ]),
	},
	watch: {
		'settings.voices.volume'() {
			this.updateVolume()
		},
		'settings.voices.muted'() {
			if (this.audioFile && this.settings?.voices?.muted) {
				this.audioFile.volume = 0
			}
			else if (this.audioFile && !this.settings?.voices?.muted) {
				this.updateVolume()
			}
		},
	},
	methods: {
		stop() {
			if (this.audioFile) {
				this.audioFile.pause()
				this.audioFile.currentTime = 0
				this.url = ''
			}
		},
		play() {
			if (this.audioFile.paused && !this.playing) {
				try {
					this.audioFile.play()
					this.playing = true
				} catch(e) { /* empty */ }
			}
		},
		pauseListener() {
			this.playing = false
		},
		endListener() {
			this.playing = false
		},
		init() {
			this.duration = this.audioFile.duration
			this.updateVolume()

			if (!this.settings?.voices?.volume && !this.settings?.sounds?.volume && !this.settings?.musics?.volume) return
			
			if (this?.autoplay && !this.settings?.voices?.muted) {
				this.play()
			}
		},
		updateVolume() {
			if (this.audioFile) {
				this.audioFile.volume = minmax(this.settings?.voices?.volume / 10, 0, 1)
			}
		},
	},
	mounted() {
		this.url = this.audioUrl
		this.audioFile = this.$refs.audio
		if (this.audioFile) {
			this.audioFile.addEventListener('loadedmetadata', this.init)
			this.audioFile.addEventListener("ended", this.endListener)
			this.audioFile.addEventListener("pause", this.pauseListener)
		}
	},
	beforeUnmount() {
		if (this.audioFile) {
			this.audioFile.removeEventListener('loadedmetadata', this.init)
			this.audioFile.removeEventListener("ended", this.endListener)
			this.audioFile.removeEventListener("pause", this.pauseListener)
			this.stop()
		}
	}
}
</script>

<style lang="stylus" scoped>

.ui-audio-player-button
	::v-deep(.ui-button)
		width 48px
		height 48px
		&[disabled]
			opacity 1 !important
			background-color #ccc
		&.dark-yellow
			pointer-events none

	::v-deep(.icon-playing)
		opacity 0.6

</style>
