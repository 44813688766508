import { useMapStore } from '@/stores/map-store'
import { useUserStore } from '@/stores/user-store'
import { medias } from '@affordancestudio/engage-game'
import _ from 'lodash'
import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue'

export function useItineraries(){
	const mapStore = useMapStore()
	const characterIllustration = ref('')
	const currentCategorySlug = ref('')
	const { categories, currentCategory } =  storeToRefs(mapStore)

	watch(() => currentCategory.value, (newVal) => {
		characterIllustration.value = getIllustration(categories.value, newVal)
		const catValue = getCatValue(newVal)		
		const currentCat = getCurrentCategoryByCatValue(categories.value, catValue)
		currentCategorySlug.value = currentCat?.slug
	})

	async function loadItinerary(){
		if(categories && _.isEmpty(categories.value))
			await useMapStore().updateCategories()

		if(currentCategory && !currentCategory?.value)
			currentCategory.value = await getCategoryInPreference()
	}

	if(currentCategory?.value){
		characterIllustration.value = getIllustration(categories.value, currentCategory.value)
		const catValue = getCatValue(currentCategory.value)		
		const currentCat = getCurrentCategoryByCatValue(categories.value, catValue)
		currentCategorySlug.value = currentCat?.slug
	}

	return {
		currentCategory,
		currentCategorySlug,
		characterIllustration,		
		loadItinerary,
	}
}

export const Categories = {
	COMPTES_LEGENDES: 'contes_legendes',
	EXPLORATION_FRANCOPHONIE: 'exploration_francophonie'
}

async function getCategoryInPreference(){
	const userStore = useUserStore()
	await userStore?.forceCheckIfConnected()
	return userStore?.userPreferences?.itinerary ?? JSON.stringify({ value: '6578836dc54fa0000b100870' })
}

function getIllustration(categories, currentCategory) {
	if (currentCategory == null) return ''
	categories = categories ?? []

	try {
		const categoryId = getCatValue(currentCategory)
		const currentCat = getCurrentCategoryByCatValue(categories, categoryId)?.clientMedias?.[0]?.id ?? ''
		const allImages = medias.findAllByTags({ tags: [ 'character', 'character-bottom-right' ] }) ?? []
		return allImages?.find(img => img?.id === currentCat)?.base64 ?? ''
		// eslint-disable-next-line no-unused-vars
	} catch(error) {
		return ''
	}
}

function getCatValue(category) {
	return JSON.parse(category)?.value ?? ''
}

function getCurrentCategoryByCatValue(categories, catValue){
	return categories?.data?.find(cat => cat?.id === catValue)
}