<template>
	<div class="modal-notification" :class="modal?.status || ''">
		<transition name="carousel" mode="out-in" appear>
			<div v-if="confirmationResended" class="carousel">
				<div class="icon">
					<ui-icon name="check-circle" :size="96" />
				</div>
	
				<ui-text class="white"  :text="$t('resendEmailConfirmationText', { email: modal?.email})" />
			</div>
			<div v-else class="carousel">
				<div class="icon">
					<ui-icon name="alert-circle" :size="96" />
				</div>
	
				<div class="text">
					<ui-text :text="$t('emailNotConfirmedResendIt', { email: modal?.email})" />
				</div>
			</div>
		</transition>
			

		<div class="buttons">
			<transition name="carousel">
				<ui-button v-if="!confirmationResended" class="carousel" color="green" :loading="loading" v-tap="() => resendEmailConfirmation()">{{ $t('forwardEmailAction') }}</ui-button>
			</transition>
			<ui-button data-id="close-btn" color="blue" v-tap="() => $emit('close')">{{ $t('close') }}</ui-button>
		</div>
		
	</div>
</template>

<script setup>
const loading = ref(false)
const confirmationResended = ref(false)

const props = defineProps({
	modal: { default: null }
})

async function resendEmailConfirmation(){
	loading.value = true
	
	const email = props?.modal?.email
	if(email) await resendConfirmation({ email })

	await wait(200)
	loading.value = false
	confirmationResended.value = true
}

</script>

<style lang="stylus" scoped>

.modal-notification
	width 100%
	max-width 520px
	padding 32px
	background-color #fff
	border-radius 24px
	text-align center
	font-size 2rem
	font-weight 500
	box-shadow 0 0 40px alpha(#1A1100, 50%)
	flex center column
	gap 16px
	&.error
		color #E25F5F
	.icon
		width 96px
		height 96px
	.title
		width 100%
	.text
		width 100%
	.buttons
		width 100%
		flex center
		justify-content space-around
		padding 4px
		border-radius 64px

.carousel
	flex center column
	gap 16px
	&.carousel-enter-active
		transition 0.35s easeOutQuart
		will-change transform, opacity
		pointer-events none
	&.carousel-enter-from
		opacity 0
		transform translateX(64px)
	&.carousel-leave-active
		transition 0.35s easeInQuart
		will-change transform, opacity
		pointer-events none
	&.carousel-leave-to
		opacity 0
		transform translateX(-64px)

.white
	color dark
</style>