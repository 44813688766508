<template>
	<div class="open-postcard" @click.stop>
		<div class="illustration" v-bg="bgImage"></div>
		<div class="content">
			<ui-scroll>
				<div class="inner">
					<div class="title"><ui-text :text="title" /></div>
					<div class="text left">
						<ui-text :text="leftPageHtml" />
						<div v-if="leftPageImage" class="image">
							<img :src="leftPageImage" :alt="leftPageImageAlt">
						</div>
					</div>
					<div class="text right">
						<ui-text :text="rightPageHtml" />
						<div v-if="rightPageImage" class="image">
							<img :src="rightPageImage" :alt="rightPageImageAlt">
						</div>
					</div>
				</div>
			</ui-scroll>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		title: {required: true, type: String},
		bgImage: {required: true, type: String},

		leftPageHtml:{type: String, default: ''},
		leftPageImage:{type: String},
		leftPageImageAlt: {type: String},

		rightPageHtml:{type: String, default: ''},
		rightPageImage:{type: String},
		rightPageImageAlt:{type: String},
	}
}
</script>

<style lang="stylus" scoped>

#tablet-vertical
	.open-postcard
		.content
			.title
				padding-top 8px
			.text.left
				font-size 1.5rem

#mobile, #tablet-vertical
	.open-postcard
		.illustration
			absolute auto
			bottom -10%
			left 50%
			width round(800px / 3)
			height round(500px / 3)
			z-index 1
			transform translateX(-50%) rotate(4deg)
			box-shadow 0 0 8px alpha(#000, 50%)
			&:before, &:after
				content ''
				position absolute
				top 32px
				height 96px
				width 35%
				background-image: linear-gradient(0deg, #ffffff 16.67%, #e3e3e3 16.67%, #e3e3e3 50%, #ffffff 50%, #ffffff 66.67%, #e3e3e3 66.67%, #e3e3e3 100%);
				background-size: 24px 24px
			&:before
				left 24px
			&:after
				right 24px
				height 72px
		.content
			background-color #F6F3E8
			padding 8px
			.ui-scroll
				.inner
					width 100%
					height auto
					// max-height 90vh
					padding 16px
					margin-bottom 64px
			.title, .text
				position relative
				left auto
				top auto
				right auto
				width 100%
				height auto
				margin 0 0 16px 0

.open-postcard
	width calc(100% - 32px)
	height calc(100% - 32px)
	max-width 800px
	max-height 500px

	.illustration
		absolute 0
		background center center no-repeat
		background-size contain

	.content
		absolute 40px
		::v-deep(.ui-scroll)
			.ui-scroll-gradient
				display none !important
		.inner
			width 100%
			height 100%

		.title
			position absolute
			left 16px
			top 16px
			font-size 2.4rem

		.text
			position absolute
			width 45%
			&.left
				left 16px
				top 64px
			&.right
				right 16px
				top 64px
			::v-deep(.ui-text)
				> p
					margin 0 0 8px 0
				> ul
					margin-left 20px
			.image
				width 100%
				height 100%
				flex center
				img
					width 100%
					height 100%
					object-fit contain


@media (max-width: 300px)
	#mobile
		.open-postcard
			.content
				padding 16px
				.title
					font-size 2.2rem
				.text.left
					font-size 1.45rem

</style>
