import { Howl } from 'howler'

const rawFiles = Object.assign(
	import.meta.glob('../assets/sounds/*.mp3', { eager: true }),
	import.meta.glob('../assets/sounds/*.mp4', { eager: true })
)
let _sounds = []

export const initSounds = ()  => {
	_sounds = Object.entries(rawFiles).map(m => {
		const file = m[1].default
		const splits = file.split('/')
		const slug = (import.meta.env.DEV ? splits[splits.length - 1] : splits[splits.length - 1].split('-').slice(0, -1).join('-')).replace('.mp3', '').replace('.mp4', '')
		return {
			slug,
			file,
			howl: new Howl({
				src: [ file ],
				volume: 1
			})
		}
	})
	console.info('🎵 INIT SOUNDS (' + _sounds.length + ')')
}

export const playSound = ({ slug }) => {
	const sound = _sounds.find(s => s.slug === slug)
	if (sound) {
		sound.howl.play()
	}
}

export const stopSound = ({ slug }) => {
	const sound = _sounds.find(s => s.slug === slug)
	if (sound) {
		sound.howl.stop()
	}
}

export const changeSoundsVolume = ({ volume }) => {
	_sounds.forEach(music => {
		music.howl.volume(volume)
	})
}

export const muteAllSounds = () => {
	_sounds.forEach(music => {
		music.howl.mute(true)
	})
}

export const unmuteAllSounds = () => {
	_sounds.forEach(music => {
		music.howl.mute(false)
	})
}
