<template>
	<div class="modal" :class="'anim-' + modal.slug">
		<div class="bg" :class="modal.background || 'blue'"></div>

		<div v-if="isScrollable" class="scrollable">
			<div class="scrollable-content">
				<div class="scrollable-content-bg" v-tap="() => { if (!modal.unclosable) removeModal(modal) }"></div>
				<div class="content">
					<component :is="'modal-' + modal.slug" :show-tuto-button="modal?.showTutoButton" :modal="modal" v-on="modal.on" @close="() => removeModal(modal)" />
				</div>
				<ui-close-button v-if="!modal.unclosable" v-tap="() => removeModal(modal)" />
			</div>
		</div>

		<div v-else class="modal-content">
			<component :is="'modal-' + modal.slug" :modal="modal" v-on="modal.on" @close="() => removeModal(modal)" />
			<ui-close-button v-if="!modal.unclosable" @click.stop v-tap="() => removeModal(modal)" :color="[ 'open-suitcase-item', 'embed-video' ].includes(modal.slug) ? 'light' : ''" />
		</div>

	</div>
</template>

<script>
// Explicitly import components from folder modals
const files = import.meta.glob('./modals/modal-*.vue', { eager: true })
let components = {}
Object.entries(files).forEach(([ path, definition ]) => {
	const componentName = path.split('/').pop().replace(/\.\w+$/, '')
	components[componentName] = definition.default
})

export default {
	props: {
		modal: { default: null },
		showTutoButton: { default: false, type: Boolean }
	},
	computed: {
		isScrollable() {
			return this.modal.scrollable === false ? false : true
		}
	},
	methods: {
		...mapActions(useAppStore, [ 'removeModal' ]),
	},
	mounted() {
		playSound({ slug: 'ui-appear' })
	},
	beforeUnmount() {
		playSound({ slug: 'ui-disappear' })
	},
	components
}
</script>

<style lang="stylus" scoped>

.modal
	absolute 0
	flex center
	z-index 3
	
	&.modal-enter-active, &.modal-leave-active
		transition opacity 0.35s easeOutQuart
		.content, .modal-content
			transition 0.25s easeOutQuart
		.close-button
			transition 0.25s easeOutQuart 0.1s !important
	&.modal-leave-active
		.close-button
			transition-delay 0s !important
	&.modal-enter-from, &.modal-leave-to
		opacity 0
		.content, .modal-content
			transform translateY(-32px)
		.close-button
			transform translateY(-32px)
			opacity 0
	
	&.anim-select-friend, &.anim-open-suitcase, &.anim-open-suitcase-item
		&.modal-enter-active, &.modal-leave-active
			transition opacity 0.25s easeOutQuart
			.modal-content
				transition 0.25s easeOutQuart
		&.modal-leave-active
			transition opacity 0.25s easeOutQuart
		&.modal-enter-from, &.modal-leave-to
			opacity 0
			.modal-content
				transform scale(0.9)

	.bg
		fixed 0
		&.dark
			background-color alpha(dark, 90%)
		&.light
			background-color alpha(#fff, 50%)
		&.blue
			background-color alpha(blue, 50%)
		&.dark-blue
			background-color alpha(dark-blue, 85%)
		&.none
			background-color transparent
			
	.scrollable
		height 100%
		width 100%
		overflow auto
		.scrollable-content
			min-height 100%
			width 100%
			flex center
			padding 32px 16px 64px 16px
			.scrollable-content-bg
				absolute 0
	.modal-content
		height 100%
		width 100%
		
			

</style>
