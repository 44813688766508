<template>
	<div class="modal-confirm-logout">
		<div class="bg" v-tap="close"></div>
		<div class="content">
			<div class="title">Tutoriel</div>
			<div class="question">
				<p>Souhaites-tu voir le tutoriel?</p>
			</div>
			<div class="answers">
				<ui-button color="red" block="true" v-tap="leave">Non</ui-button>
				<ui-button color="green" block="true" v-tap="restartTuto">Oui</ui-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	inject: ['tutorialService'],
	props: {
		popup: { default: null }
	},
	methods: {
		leave() {
			this.close()
		},
		close() {
			this.$emit('close')
		},
		restartTuto() {
			let parameters = [
				{tag: 'onboarding'}
			]
			this.tutorialService.replay({parameters})
			this.tutorialService.playWithParameters({parameters: [{tag: 'onboarding'}, {tag: 'first'}]})
			this.close()
		}
	},
}
</script>

<style lang="stylus" scoped>

.modal-confirm-logout
	absolute 0
	flex center
	.bg
		absolute 0
	> .content
		width calc(100vw - 32px)
		max-width 520px
		padding 32px
		flex center column
		gap 48px
		border-radius 8px
		color #fff
		font-size 2rem
		text-align center
		.title
			width 100%
			margin 0 0 16px 0
			font-size 3.2rem
			border-bottom 3px solid blue
		.question
			width 100%
			flex center column
			gap 16px
			> p
				width 100%
				strong
					font-weight 500
		.answers
			width 100%
			flex center
			gap 16px


</style>
