import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from '@/router'
import TouchEvents from '@/js/plugins/touch-events'
import { backgroundImage } from '@/js/plugins/background-image'
import { fitText } from '@affordancestudio/functions'
import messages from '@/data/texts.js'
import { init } from '@affordancestudio/affordance-sentry'
import { tutorialService as tutoService } from '@/composable/tutorial-service'
import { useMapStore } from '@/stores/map-store'
import { useTutoStore } from '@/stores/tuto-store'
import { useUserStore } from '@/stores/user-store'
import { useGroupStore } from '@/stores/group-store'
import { useMusicService } from '@/composable/musicService'
import { musicMapping } from './data/music'
import {useAppStore} from '@/stores/app-store'

const i18n = createI18n({
	locale: getLang(),
	fallbackLocale: 'en',
	messages
})

const app = createApp(App)
const pinia = createPinia()
app.use(pinia)

const mapStore = useMapStore()

const tutorialService = tutoService({
	appStore: useAppStore(),
	groupStore: useGroupStore(),
	mapStore,
	tutoStore: useTutoStore(),
	userStore: useUserStore(),
})

const musicService = useMusicService({
	musicMapping,
	mapStore,
})

const isSentry = import.meta.env.VITE_APP_SENTRY_ON === 'true'
if (isSentry) {
	const dsn = import.meta.env.VITE_APP_SENTRY_DSN
	const environment = import.meta.env.VITE_APP_ENV
	const target = import.meta.env.VITE_APP_SENTRY_TARGET
	init({ app, dsn, environment, router, target })
}

pinia.use(({ store }) => {
	store.$tutorialService = tutorialService
})

app.provide('tutorialService', tutorialService)
app.provide('musicService', musicService)
app.use(i18n)
app.directive('bg', backgroundImage)
app.directive('fit-text', fitText)
app.use(TouchEvents, { events: [ 'tap', 'panstart', 'pan', 'panend' ] })
app.use(router)
app.mount('#app')
