import {GLOBAL_YUP_VALIDATIONS} from '@/helpers/form-helper'
import {loginStudent} from '@/requests/home-requests'

const {email} = GLOBAL_YUP_VALIDATIONS

export default {
	adminLoginForm: {
		title: 'Connexion à un compte de gestion',
		validationSchema: yup.object({email: email(yup)}),
		fields: [
			{
				'data-id': 'email',
				name: 'email',
				label: 'Courriel',
				as: 'input',
				autocomplete: 'email',
				showValidationMsg: true
			},
			{
				'data-id': 'password',
				name: 'password',
				label: 'Mot de passe',
				as: 'input',
				type: 'password',
			},
			{
				'data-id': 'forgot-password',
				name: 'link',
				label: 'Mot de passe oublié',
				as: 'a',
				route: 'forgot'
			},
			{
				'data-id': 'confirm-btn',
				name: 'submit',
				text: 'Confirmer',
				as: 'button',
				class: 'green'
			},
		],
	},
	studentLoginForm: {
		title: 'Connexion à un compte de jeu avec code de groupe',
		fields: [
			{
				'data-id': 'username',
				name: 'userName',
				label: 'Nom d\'usager',
				as: 'input',
			},
			{
				'data-id': 'password',
				name: 'password',
				label: 'Mot de passe',
				as: 'input',
				type: 'password',
			},
			{
				'data-id': 'confirm-btn',
				name: 'submit',
				text: 'Confirmer',
				as: 'button',
				class: 'green'
			},
		],
		submit: loginStudent
	},
	anonymousLoginForm: {
		title: 'Connexion à un compte de jeu sans code de groupe',
		validationSchema: yup.object({email: email(yup)}),
		fields: [
			{
				'data-id': 'email',
				name: 'email',
				label: 'Courriel',
				as: 'input',
				showValidationMsg: true
			},
			{
				'data-id': 'password',
				name: 'password',
				label: 'Mot de passe',
				as: 'input',
				type: 'password',
			},
			{
				'data-id': 'forgot-password',
				name: 'link',
				label: 'Mot de passe oublié',
				as: 'a',
				route: 'forgot'
			},
			{
				'data-id': 'confirm-btn',
				name: 'submit',
				text: 'Confirmer',
				as: 'button',
				class: 'green'
			},
		],
	},

}