<template>
	<div class="suitcase-item" :id="'item-' + id" :data-position="position" :class="classes" @click.stop>
		<div class="illustration shadow" v-bg="cover"></div>
		<div class="illustration" v-bg="cover"></div>
	</div>
</template>

<script>

export default {
	props: {
		id: {required: true, type: String},
		position: {required: true, type: Number, default: 1},
		rotation: {required: true, type: Number, default: 0},
		cover: {required: true, type: String},
		zIndex: {required: true, type: Number},
		tags: {type: Array, default: []},
	},
	computed: {
		classes() {
			return [
				`z-${this.zIndex}`,
				`r-${this.rotation}`,
				...this.tags
			]
		}
	}
}
</script>

<style lang="stylus" scoped>

.suitcase-item
	position absolute
	border-radius 50%
	width 60%
	height 48%
	transform scale(0.5)
	transition 0.15s easeOutQuart
	cursor pointer

	&[data-position="1"]
		left 0%
		bottom 50%
	&[data-position="2"]
		left 40%
		bottom 50%
	&[data-position="3"]
		left -2%
		bottom -2%
		.illustration
			background-position bottom center
	&[data-position="4"]
		left 9%
		bottom 12%
	&[data-position="5"]
		left 35%
		bottom 12%
		z-index 1
	&[data-position="6"]
		left 42%
		bottom -2%
		.illustration
			background-position bottom center
	&[data-position="7"]
		left 20%
		bottom 50%

	for i in -90..90
		&.r-{i}
			transform 'rotate(%sdeg) scale(0.5)' % i

	for i in -10..10
		&.z-{i}
			z-index i

	&:hover
		z-index 4
		.illustration
			transform translateY(-16px)
			&.shadow
				transform scale(1.05)
				opacity 0.25

	&.papier .illustration
		absolute 64px

	.illustration
		absolute 0
		background center center no-repeat
		background-size contain
		transition 0.15s easeOutQuart
		pointer-events none
		&.shadow
			filter brightness(0) blur(4px)
			opacity 0.5
			transform translate(0px, 2px)


</style>
