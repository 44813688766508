<template>
	<div class="open-letter" @click.stop>
		<div class="illustration" v-bg="bgImage"></div>
		<div class="content">
			<ui-text :text="text" />
			<form v-if="!showConfirmation" class="form" @submit.prevent="postComment">
				<textarea ref="input" v-model="comment" rows="6" class="input" placeholder="Entre une réponse ici..."></textarea>
				<div class="buttons">
					<ui-button type="submit" size="small" :loading="loading" :disabled="isFormDisabled">Envoyer</ui-button>
				</div>
			</form>
			<transition name="fade">
				<div v-if="showConfirmation" class="confirmation-message">Merci, ta réponse a été envoyée!</div>
			</transition>
		</div>
	</div>
</template>

<script>

export default {
	data() {
		return {
			loading: false,
			comment: '',
			showConfirmation: false,
		}
	},
	props: {
		id: { required: true, type: String },
		bgImage: { required: true, type: String },
		text: { required: true, type: String },
	},
	computed: {
		...mapState(useAppStore, [ 'device' ]),
		isFormDisabled() {
			return this.comment.length < 3 || !this.id
		}
	},
	methods: {
		async postComment() {
			if (!this.loading && !this.isFormDisabled) {
				this.loading = true
				await evaluate({ containerId: this.id, comment: this.comment })
				this.showConfirmation = true

				setTimeout(() => {
					this.showConfirmation = false
					this.comment = ''
					this.loading = false
				}, 2000)
			}
		}
	},
	mounted() {
		if (this.device === 'desktop') this.$refs?.input?.focus()
	}
}
</script>

<style lang="stylus" scoped>

#mobile
	.open-letter
		width 500px
		height 800px
		.illustration
			background right 12px top 80px no-repeat
			background-size 475px auto
		.content
			top 88px
			right 20px
			width 334px
			height 480px

.open-letter
	width 100%
	height 100%
	max-width 600px
	max-height 650px

	.illustration
		absolute 0
		background center center no-repeat
		background-size auto 100%

	.content
		position absolute
		right 5%
		top 2%
		width 65%
		height 86%
		padding 16px
		font-size 1.5rem
		::v-deep(.ui-text)
			> h1
				margin 0 0 16px 0
				font-size 2.4rem
			> p
				margin 0 0 8px 0

		.form
			position absolute
			left 16px
			bottom 16px
			right 16px
			flex center column
			gap 16px
			.input
				display block
				width 100%
				padding 16px
				resize none
				background alpha(#fff, 25%)
				border 2px solid #DCD6D6
				font inherit
				color inherit
				outline none
				&:focus
					border-color blue
			.buttons
				width 100%
				flex end
		.confirmation-message
			position absolute
			top calc(75% - 8px)
			left 0
			height 100%
			width 100%
			color #91827A
			font-size 2rem
			text-align center
			&.fade-enter-active
				transition 0.3s easeOutQuart
			&.fade-enter-from
				opacity 0
				transform translateY(-16px)
			&.fade-leave-to
				opacity 0
				transition 0.2s easeOutQuart

</style>
