<template>
	<div class="open-picture" @click.stop>
		<div class="illustration" v-bg="bgImage"></div>
		<div class="content">
			<ui-scroll>
				<div class="inner">
					<div class="title"><ui-text :text="title" /></div>
					<div class="text left">
						<ui-text :text="leftPageHtml" />
						<div v-if="leftPageImage" class="image">
							<img :src="leftPageImage" :alt="leftPageImageAlt">
						</div>
					</div>
					<div class="text right">
						<ui-text :text="rightPageHtml" />
						<div v-if="rightPageImage" class="image">
							<img :src="rightPageImage" :alt="rightPageImageAlt">
						</div>
					</div>
				</div>
			</ui-scroll>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		title: {required: true, type: String},
		bgImage: {required: true, type: String},

		leftPageHtml:{type: String, default: ''},
		leftPageImage:{type: String},
		leftPageImageAlt: {type: String},

		rightPageHtml:{type: String, default: ''},
		rightPageImage:{type: String},
		rightPageImageAlt:{type: String},
	},
}
</script>

<style lang="stylus" scoped>

#mobile, #tablet-vertical
	.open-picture
		max-height none
		height auto
		.illustration
			display none
		.content
			absolute auto
			position relative
			background-color #F6F3E8
			padding 12px
			border-radius 8px
			&:before
				content ''
				absolute 12px
				background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23BB9C7BFF' stroke-width='4' stroke-dasharray='6%2c 16' stroke-dashoffset='12' stroke-linecap='square'/%3e%3c/svg%3e");
				border-radius @border-radius
			.ui-scroll
				.inner
					width 100%
					max-height 90vh
					padding 32px
			.title, .text
				position relative
				left auto
				top auto
				right auto
				width 100%
				height auto
				margin 0 0 16px 0
			.image
				width 100%
				height auto

.open-picture
	width calc(100% - 32px)
	height calc(100% - 32px)
	max-width 800px
	max-height 489px

	.illustration
		absolute 0
		background center center no-repeat
		background-size contain

	.content
		absolute 20px
		.ui-scroll
			.ui-scroll-gradient
				display none
		.inner
			width 100%
			height 100%
		.title
			position absolute
			left 24px
			top 40px
			font-size 2.4rem

		.text
			position absolute
			width 44%
			padding-bottom 16px
			&.left
				left 24px
				top 96px
			&.right
				right 24px
				top 96px
			::v-deep(.ui-text)
				> p
					margin 0 0 8px 0
				> ul
					margin-left 20px
			.image
				width 100%
				height 100%
				flex center
				&.square
					margin-top 64px
					height calc(100% - 64px)
				img
					width 100%
					height 100%
					object-fit contain

</style>
