export const moveElementToCenterOfScreen = async ({ $el, scale = 1, zIndex = false, delay = 750 }) => {
	const app = document.querySelector('#app')?.getBoundingClientRect()
	const rect = $el?.getBoundingClientRect()
	if (app && rect) {
		const x = (app.width / 2 - rect.width / 2 - (rect.x - app.x))
		const y = (app.height / 2 - rect.height / 2 - (rect.y - app.y))
		$el.style.pointerEvents = 'none'
		$el.style.transition = '0.25s cubic-bezier(0.470, 0.000, 0.745, 0.715)'
		$el.style.transform = 'translate(' + x + 'px, ' + y + 'px) scale(' + scale + ')'
		if (zIndex) $el.style.zIndex = zIndex
		if (delay) await wait(delay)
	}
}

export const resetElementToCenterOfScreen = ({ $el }) => {
	$el.style.pointerEvents = ''
	$el.style.transition = ''
	$el.style.transform = ''
	$el.style.zIndex = ''
}

export function getJournal(category) {
	const isDefaultItinerary = json().parse(category).getOrDefault()?.value === '6578836dc54fa0000b100870'
		
	return { 
		cover: isDefaultItinerary ? 'user/journal-cover.png' : 'user/journal-cover-contes.png',
		color: isDefaultItinerary ? '#A59C84' : '#95AFAF'
	}
}

export function getBookmark(sections){
	return transpose(sections)
		.get('clientFiles')
		.flat()
		?.filter(cf => 	cf?.tags?.includes('fin_itineraire'))
		?.[0]
}