const modules = import.meta.globEager('../../assets/img/**/*.(png|svg|jpg|gif)')

console.info('🏞 INIT LOCAL MEDIAS (' + Object.keys(modules).length + ')')

const getImageUrl = (name, warn = true) => {
	if (name.startsWith('data:') || name.startsWith('/') || name.startsWith('http')) return name
	const path = '../../assets/img/'
	const image = modules[path + name]
	if (image) return image.default
	if (warn) console.warn('Image not found:', name)
	return ''
}

export const backgroundImage = {
	beforeMount (el, binding) {
		if (binding.value) el.style.backgroundImage = 'url(' + getImageUrl(binding.value) + ')'
	},
	updated(el, binding) {
		if (binding.value) el.style.backgroundImage = 'url(' + getImageUrl(binding.value) + ')'
	}
}
