import { get, post } from '@/requests/requests'

export const getAllTutorials = async () => {
	return await get('myTutorials')
}

export const updateTutorial = async ({ id, status }) => {
	return await post('changeTutorialStatus', { tutorialId: id, status })
}

export const openFile = async ({ id }) => {
	return await post('openFile', { clientFileId: id })
}