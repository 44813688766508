<template>
	<div class="modal-open-suitcase-item">
		<div class="bg" v-tap="close" @click.stop></div>

		<open-postcard v-if="isType('postcard')" v-bind="modal.item.content" />
		<open-letter v-if="isType('lettre')" v-bind="modal.item.content" />
		<open-picture v-if="isType('picture')" v-bind="modal.item.content" />
		<open-notebook v-if="isType('cahier')" v-bind="modal.item.content" />
		<open-paper v-if="isType('papier')" v-bind="modal.item.content" />
		<open-phone v-if="isType('phone')" v-bind="modal.item.content" />

	</div>
</template>

<script>
export default {
	props: {
		modal: { default: null }
	},
	methods: {
		isType(type){
			return this.modal?.item?.type == type
		},
		close() {
			this.$emit('close')
		},
	}
}
</script>

<style lang="stylus" scoped>

.modal-open-suitcase-item
	absolute 0
	flex center
	> .bg
		absolute 0
					

</style>