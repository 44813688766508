import { engage } from '@affordancestudio/engage-game'
import { error } from '@/requests/requests'

engage.setOptions({
	createAnonymousUser: false,
	env: {
		VUE_APP_CLIENT_ID: import.meta.env.VITE_APP_CLIENT_ID,
		VUE_APP_END_POINT_STAGE: import.meta.env.VITE_APP_END_POINT_STAGE,
		VUE_APP_END_POINT_TYPE: import.meta.env.VITE_APP_END_POINT_TYPE,
		VUE_APP_ENV: import.meta.env.VITE_APP_ENV,
		VUE_APP_PROXY_END_POINT: import.meta.env.VITE_APP_PROXY_END_POINT,
	}
})

const lang = getLang()
engage.setLanguage({ language: [ 'en', 'fr' ].includes(lang)  ? lang : 'en' })
engage.setError(e => error(e))

console.info('🤖 INIT ENGAGE')

export { engage }
