<template>
	<div class="open-paper" @click.stop>
		<div class="illustration" v-bg="bgImage"></div>
		<div class="content">
			<ui-scroll>
				<div class="inner">
					<h1 class="title">
						<ui-text v-if="title" :text="title" />
					</h1>
					<ui-text :text="content" />
				</div>
			</ui-scroll>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		title: {required: true, type: String},
		bgImage: {required: true, type: String},
		content: {required: true, type: String},
	},
}
</script>

<style lang="stylus" scoped>

#mobile
	.open-paper
		height auto
		.illustration
			background-position center center
			background-size cover
		.content
			absolute auto
			position relative
			padding 32px 16px
			.inner
				padding 16px
				height 340px

.open-paper
	width calc(100% - 32px)
	height calc(100% - 32px)
	max-width 470px
	max-height 650px

	.illustration
		absolute 0
		background top center no-repeat
		background-size 470px 650px

	.content
		position absolute
		top 64px
		bottom 64px
		left 40px
		right 40px
		::v-deep(.ui-scroll)
			.ui-scroll-gradient
				display none !important
		.inner
			padding 16px
			font-size 1.5rem
			.title ::v-deep(.ui-text) > p
				font-family IndieFlower
				margin 0 0 16px 0
				font-size 2.4rem
				font-weight 400
			::v-deep(.ui-text)
				> p
					margin 0 0 8px 0
				> .chains-separator
					display block
					margin 0 0 8px 0
					width 100%
					height 32px
					background url('@/assets/img/discoveries/chains-separator.svg') center center no-repeat
					background-size auto 16px

</style>
