import { useAppStore } from "@/stores/app-store"
import { useUserStore } from "@/stores/user-store"

export function useResetProgress() {
	const router = useRouter()
	const { logout } = useUserStore()
	const appStore = useAppStore()
	return {
		resetProgress: () => {
			logout()
			localStorage?.removeItem?.('activeAudio')
			appStore?.resetSettings?.()
			router.push({ name: 'home' }) 
		}
	}
}