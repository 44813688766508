<template>
	<div class="modal-add-group">

		<div class="title">Ajouter un groupe</div>

		<div class="content">
			<div class="input-text">Nom de groupe</div>
			<ui-input data-id="group-name" v-model="groupName" class="ui-input"></ui-input>
			<div>Un code de groupe vous sera donné une fois le groupe créé. Les membres pourront automatiquement populer votre groupe grâce au code de groupe.</div>
		</div>
		
		<ui-button data-id="confirm-btn" class="ui-btn" color="green" @click="addGroup(groupName)">Confirmer</ui-button>
	
	</div>
</template>

<script>
export default {
	data() {
		return {
			groupName: '',
		}
	},
	methods: {
		...mapActions(useGroupStore, ['createGroup', 'loadMyGroups']),

		async addGroup() {
			await this.createGroup(this.groupName)
			await this.loadMyGroups()
			this.$emit('close')
		},
	},
	mounted() {
		this.loadMyGroups()
	}
}
</script>

<style lang="stylus" scoped>
	.modal-add-group
		max-width 500px
		background-color #fff
		padding 16px 24px 24px 24px
		border-radius 16px
		display flex
		flex-direction column
		gap 16px
		.title
			border-bottom 2px solid #3B4851
			font-size 2rem
			font-weight 500
			text-transform uppercase
			text-align center
		.content
			display flex
			flex-direction column
			gap 4px
			.input-text
				text-transform uppercase
			.ui-input
				border solid 1px #3B4851
				border-radius 4px
		.ui-btn
			height auto
			padding 8px
			width calc(100% - 48px)
			margin 0 auto

</style>