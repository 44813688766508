<template>
	<div class="modal-notification" :class="modal?.status || ''">

		<div class="icon">
			<ui-icon v-if="modal?.status === 'error'" name="alert-circle" :size="96" />
			<ui-icon v-if="modal?.status === 'success'" name="check-circle" :size="96" />
		</div>

		<div v-if="modal.text" class="text">
			<p v-if="modal?.status === 'error'">{{ $t('errorModal.title') }}</p>
			<ui-text :text="modal.text" />
		</div>

		<div class="buttons">
			<ui-button data-id="close-btn" color="blue" v-tap="() => $emit('close')">{{ $t('close') }}</ui-button>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		modal: { default: null }
	},
}
</script>

<style lang="stylus" scoped>

.modal-notification
	width 100%
	max-width 520px
	padding 32px
	background-color #fff
	border-radius 24px
	text-align center
	// color #fff
	font-size 2rem
	font-weight 500
	box-shadow 0 0 40px alpha(#1A1100, 50%)
	flex center column
	gap 16px
	&.error
		color #E25F5F
	&.success
		color green
	.icon
		width 96px
		height 96px
	.title
		width 100%
	.text
		width 100%
	.buttons
		flex center
		padding 4px
		border-radius 64px

</style>