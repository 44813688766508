import {hasPropWithValue, isValue, toDict, transpose} from '@/js/utils'
import { isEmpty } from 'lodash'

/** 
    @param {{
        clientMedias: [ 
            { id, tags: ['background', 'section_quebec', 'type_stamps'] }
        ],
    }} itinerary
*/
export const getLootItemService = (itinerary) => {
	/*
	* The clientMedias array contains the loot items backgrounds.
	* To identify the type and section of the loot item, we use tags.
	* The tag 'section_[name]' is used to identify the section of the loot item
	* The tag 'type_[name]' is used to identify the type of the loot item
	*/
	const backgrounds = transpose(itinerary)
		.get('clientMedias')
		.filter(cm => isValue(cm) && cm?.tags?.includes?.('background'))
		.map(getLootItem)
		.filter(hasPropWithValue('section'))
		.value

	const sectionLootItemDict = toDict(backgrounds, 'section')

	return {
		/**
		* @param {string} sectionName
		* @return {{media_id, section, type}}
		*/
		findBySectionName(sectionName){
			return sectionLootItemDict?.[sectionName] ?? {type: 'stamps'}
		}
	}
}
const findPrefix = (prefix) => (tag) => tag?.startsWith(prefix)
const getSuffix = (arr) => isEmpty(arr) || arr.length <= 1 ? null : arr[1]
const splitAndGetSuffix = (text) => getSuffix(text?.split?.('_'))

const findTagByPrefixAndGetSuffix = (tags, prefix) => transpose(tags)
	.find(findPrefix(prefix))
	.map(splitAndGetSuffix)
	.value
const getLootItem = b => ({
	type: findTagByPrefixAndGetSuffix(b?.tags, 'type_'),
	section: findTagByPrefixAndGetSuffix(b?.tags, 'section_'),
	media_id: b.id,
})
