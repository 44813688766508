<template>
	<div class="modal-edit-student">
		<div class="title">Modifier un compte de jeu</div>
		<ui-loader v-if="isLoading" class="loader" color="dark" size="48"></ui-loader>

		<div v-if="!editSuccess" class="container">
			<div class="intro-text-wrap">
				<span>Vous vous apprêtez à modifier les informations de l'utilisateur</span>
				<span class="current-username">{{ currentMemberInfo?.userName ?? '' }}.</span>
				<br>
				<span>N’oubliez pas de communiquer ces nouvelles informations au membre associé au compte.</span>
			</div>
			<div class="content">
				<div class="info-wrap">
					<div class="info-title">Prénom:</div>
					<input v-model="firstName" class="input name" :placeholder="currentMemberInfo?.firstName || ''" />
				</div>
				<div class="info-wrap">
					<div class="info-title">Nom d'usager:</div>
					<input v-model="userName" class="input username" :placeholder="currentMemberInfo?.userName || ''" />
				</div>
			</div>
			<div class="btn-wrap">
				<ui-button class="ui-btn" color="red" v-tap="close">Annuler</ui-button>
				<ui-button class="ui-btn" color="green" @click="confirmEdit">Confirmer</ui-button>
			</div>
		</div>

		<div v-else class="confirmation-wrap">
			<div class="confirmation-message">Informations mises à jour.</div>
			<ui-button class="ui-btn" color="green" @click="close">Fermer</ui-button>
		</div>

	</div>
</template>

<script>
export default {
	data() {
		return {
			userName: '',
			firstName: '',
			editSuccess: false,
			isLoading: false,
		}
	},
	emits: [ 'close' ],
	computed: {
		...mapState(useGroupStore, ['currentMemberInfo']),
	},
	methods: {
		...mapActions(useGroupStore, ['editCurrentMember']),
		close() {
			this.$emit('close')
			this.editSuccess = false
		},
		async confirmEdit() {
			this.isLoading = true

			const userName = this.userName !== '' ? this.userName : this.currentMemberInfo?.userName
			const firstName = this.firstName !== '' ? this.firstName : this.currentMemberInfo?.firstName

			if (userName || firstName) await this.editCurrentMember(userName, firstName)

			this.isLoading = false
			this.editSuccess = true
		}
	},
	async mounted() {
		await this.editCurrentMember()
	}
}
</script>

<style lang="stylus" scoped>
	.modal-edit-student
		max-width 500px
		background-color #fff
		padding 16px 24px 24px 24px
		border-radius 16px
		display flex
		flex-direction column
		gap 24px
		text-align center
		.title
			border-bottom 2px solid #3B4851
			padding-bottom 8px
			font-size 2rem
			font-weight 500
			text-transform uppercase
			text-align center
		.loader
			position absolute
			top 50%
			left 50%
			transform translate(-50%, -50%)
		.container
			.intro-text-wrap
				font-weight 500
				text-wrap balance
				.current-username
					padding-left 4px
					text-transform none
			.content
				display flex
				flex-direction column
				gap 16px
				padding 24px 0
				.info-wrap
					display flex
					justify-content space-between
					.info-title
						font-weight 600
					.input
						padding 4px 8px
					::placeholder
						color dark
						opacity 1
			.btn-wrap
				display flex
				gap 16px
				.ui-btn
					height auto
					padding 8px
					width calc(100% - 48px)
					font-size 1.8rem

		.confirmation-wrap
			.confirmation-message
				padding-bottom 24px
			.ui-btn
				height auto
				padding 8px 16px
				font-size 1.8rem

</style>
