<template>
	<div class="row">
		<p class="label">{{ label }}</p>
		<div class="value">
			<button class="volume-btn" v-tap="() => changeVolume({ volume, muted: !muted })">
				<div :class="['volume-icon', { 'muted': muted }]" class="volume-icon"></div>
			</button>
			<div class="volume-button" v-tap="() => changeVolume({ volume: volume - 1, muted: false })">-</div>
			<div class="dots">
				<div v-for="i in 10" :key="i" :data-id="`${slug}-ctrl-${i <= volume ? 'active' : 'inactive'}`" :class="{ active: i <= volume, muted: muted }" class="dot" v-tap="() => changeVolume({ volume: i, muted: false })"></div>
			</div>
			<div class="volume-button" v-tap="() => changeVolume({ volume: volume + 1, muted: false })">+</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		label: String,
		volume: Number,
		muted: Boolean,
		slug: String,
	},
	methods: {
		changeVolume({ volume, muted }) {
			this.$emit('change', { volume, muted })
		},
	}
}
</script>

<style lang="stylus" scoped>
	
	.row
		width 100%
		min-height 72px
		padding 8px 0
		border-radius 8px
		.label
			width 100%
			flex-shrink 1
			text-align center
			.small
				font-size 1.6rem
		.value
			width 100%
			flex center
			gap 16px
			background-color #000
			padding 16px
			border-radius 8px
			color #fff
			.volume-btn
				background none
				border none
				cursor pointer
				position absolute
				left 24px
				.volume-icon
					background url(@/assets/img/audio-on.svg) center no-repeat
					background-size 100%
					width 24px
					height 24px
					&.muted
						background url(@/assets/img/audio-off-settings.svg) center no-repeat
						background-size 100%
			.volume-button
				width 24px
				height 24px
				flex center
				font-size 2.4rem
				font-weight 600
				cursor pointer
				&:active
					transform scale(0.8)
			.dots
				flex center
				gap 6px
				.dot
					height 24px
					width 6px
					border-radius 4px
					background-color #666666
					cursor pointer
					&.active
						background-color #fff
						&.muted
							opacity 70%

@media (max-width: 420px)
	.value
		min-width calc(100vw - 48px)
		left -24px
</style>
