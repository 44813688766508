<template>
	<div class="modal-settings">
		<div class="bg" v-tap="() => $emit('close')"></div>
		<div v-if="isNoValue(user) || isGuest(user)" class="content">
			<div class="title">Profil</div>

			<p class="label text-center">{{ getAccountType(user) }}</p>

			<div class="row mt-10 flex flex-col gap-2">
				<ui-button class="logout-button" color="blue" :block="true" v-tap="() => goToRouteAndClose('signup', { query : { keepContextUser: isValue(user) }})">Créer un compte</ui-button>
				<ui-button class="logout-button" color="blue" :block="true" v-tap="() => goToRouteAndClose('login')">Se connecter</ui-button>

				<template v-if="isGuest(user)">
					<div class="separator"></div>
	
					<ui-button class="logout-button" data-id="reset-progress-btn" color="red" :block="true" v-tap="showPopupResetProgress">Effacer ma progression</ui-button>
				</template>
				
			</div>
		</div>
		<div v-else class="content">
			<div class="title">Profil</div>

			<div class="row">
				<p class="label">Information de connexion</p>
				<div class="value px-3">
					<p class="label small">{{ getUserName(user) }}</p>
				</div>
			</div>

			<div class="row">
				<p class="label">Type de compte</p>
				<div class="value">
					<p class="label small">{{ getAccountType(user) }}</p>
				</div>
			</div>

			<div class="row mt-3">
				<ui-button class="logout-button" color="red" :block="true" v-tap="leave">Se déconnecter</ui-button>
			</div>
		</div>
	</div>
</template>

<script setup>
import { isValue } from '@/js/utils'
import { useUserStore } from '@/stores/user-store'
import { isNoValue } from '@affordancestudio/functions'
import { useRouter } from 'vue-router'

defineProps({
	modal: { default: null }
})
const { addModal }			= useAppStore()
const emit 							= defineEmits(['close'])
const router 						= useRouter()
const goToRouteAndClose = (routeName, opts = {}) => {
	playSound({ slug: 'click' })
	router.push({ name: routeName, ...opts })
	emit('close')
}


const {
	user,
	logout
} = useUserStore()

const leave = async () => {
	await logout()
	router.push({ name: 'home' })
	emit('close')
}

const isUserDisconnected	= user => isNoValue(user)
const isTeacher 					= user => user?.teacher
const isGuest 						= user => user?.onboarding === 'is_anonymous'
const isGamer 						= user => user?.onboarding === 'is_validated' && !user?.teacher
const isUserWithGroupCode = user => user?.onboarding === 'is_validated' && user?.groups?.length > 0

const accountTypes 		= [
	{
		when				: isUserDisconnected,
		accountType	: 'Tu n\'es présentement pas connecté à un compte. Ta progression n\'est pas sauvegardé.'
	},
	{
		when				: isTeacher,
		accountType	: 'Compte de gestion'
	},
	{
		when				: isGuest,
		accountType	: 'Tu n\'es présentement pas connecté à un compte. Ta progression n\'est pas sauvegardé.'
	},
	{
		when				: isUserWithGroupCode,
		accountType	: 'Compte de jeu avec code de groupe'
	},
	{
		when				: isGamer,
		accountType	: 'Compte de jeu sans code de groupe'
	},
]

const getAccountType 	= user => accountTypes.find(at => at.when(user))?.accountType ?? ''
const getUserName 		= user => !user?.email || user?.email === '' 
	? user?.userName
	: user?.email

const showPopupResetProgress = () => addModal({ slug: 'reset-progress', background: 'dark-blue' })

</script>

<style lang="stylus" scoped>

.separator
	border-bottom 3px solid blue

.modal-settings
	absolute 0
	flex center
	.bg
		absolute 0
	> .content
		width calc(100vw - 32px)
		max-width 440px
		padding 32px
		flex center column
		gap 8px
		border-radius 8px
		color #fff
		font-size 2rem
		.title
			width 100%
			margin 0 0 16px 0
			font-size 3.2rem
			text-align center
			border-bottom 3px solid blue
		.row
			width 100%
			min-height 72px
			padding 8px 0
			border-radius 8px
			.small
				font-size 1.6rem
			.label
				width 100%
				flex-shrink 1
				text-align center
				.small
					font-size 1.6rem
			.value
				width 100%
				flex center
				gap 16px
				background-color #000
				padding 16px
				border-radius 8px
				color #fff
				.logout-button
					border-radius 8px
					&:after
						border-radius 8px

</style>
