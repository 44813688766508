<template>
	<div class="modal-text">
		<div class="bg" v-tap="close"></div>
		<div class="content">
			<div v-if="modal.title" class="title" v-html="modal.title"></div>
			<div v-if="modal.text" class="texts" v-html="modal.text"></div>
		</div>
		<div class="buttons">
			<ui-button color="green" v-tap="close">Fermer</ui-button>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		modal: { type: Object, required: true }
	},
	emits: [ 'close' ],
	methods: {
		close() {
			this.$emit('close')
		}
	}
}
</script>

<style lang="stylus" scoped>

.modal-text
	display block
	.bg
		absolute 0
	> .content
		width calc(100vw - 32px)
		max-width 500px
		padding 32px
		flex center column
		gap 32px
		background-color #eee
		border-radius 8px
		color dark
		font-size 1.6rem
		text-align center
		height calc(100vh - 196px)
		.title
			width 100%
			padding-bottom 16px
			border-bottom 3px solid blue
			font-size 2.4rem
			line-height 1.2em
			font-weight 500
			text-transform uppercase
		.texts
			height calc(100% - 80px)
			overflow scroll
			p
				margin 0 0 16px 0
				strong
					font-weight 600
				a
					color inherit
					text-decoration underline
					&:hover
						text-decoration none
	> .buttons
		width 100%
		flex center
		padding 32px 0 0 0
		gap 16px
		.ui-button
			text-transform uppercase
</style>