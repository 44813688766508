<template>
	<span class="ui-text" v-html="visibleText"></span>
</template>

<script>
export default {
	props: {
		text: { default: '' },
	},
	computed: {
		visibleText() {
			if (!this?.text) return ''
			return (this.text.replaceAll(' ?', '&nbsp;?') ?? this.text)
		}
	}
}
</script>

<style lang="stylus" scoped>

.ui-text
	font inherit
	color inherit
	::v-deep(.green)
		color #4FA24C
		font-weight 900
	::v-deep(.yellow)
		color #FFDA44
		font-weight 900
	::v-deep(.red)
		color #A24C4C
		font-weight 900
	::v-deep(strong)
		font-weight 600

	// ::v-deep(.icon-settings)
	// 	display block
	// 	margin 16px auto
	// 	width 64px
	// 	height 64px
	// 	background url('@/assets/img/badge-main-character.svg') center center no-repeat
	// 	background-size contain

</style>
