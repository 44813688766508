<template>
	<div class="modal-open-lottery" @click.stop>
		<div class="bg"></div>
		<div class="container">
			<h2 class="title">TIRAGE</h2>
			<hr class="horizontal-line" />
			<p class="description">Le tirage s’adresse aux personnes intervenant auprès de jeunes au Canada.</p>
			<p class="description mt-description">Remplis ce formulaire avant le 31 mars pour gagner un ensemble-cadeaux pour ta classe!</p>
			<img class="img" src="@/assets/img/lottery.png" alt="Cadeaux">
			<div class="footer">
				<div>
					<ui-button class="btn" color="red" :class="alert" v-tap="close">Remplir plus tard</ui-button>
					<p class="btn-description">Retrouve le tirage dans la zone pédagogique</p>
				</div>
				<ui-button class="btn" color="green" :class="success" v-tap="submit">Remplir maintenant</ui-button>
			</div>
		</div>
	</div>
</template>

<script setup lang="js">
const emits = defineEmits(['close'])

function submit(){
	window.open('https://forms.office.com/Pages/ResponsePage.aspx?id=P23TQ7aWuEim9BsQi_WcByuv5XqQAS9JqgbDWptDAXhUMFJZM0RGVlNTV1A0WDFJRTBJUkVVODBLQy4u', '_blank');
}

function close(){
	emits('close')
}
</script>

<style lang="stylus" scoped>
$black = #54606C

.container
	border-radius 16px
	padding 16px 24px
	display flex
	flex-direction column
	justify-content center
	align-items center
	width 612px
	max-width 95vw !important
	background-color #fff
	
	.title
		color $black
		text-align center
		font-size 2.5rem
		padding-bottom 8px

	.img
		width 360px
		max-width 100%

	.description
		color $black
		font-size 2rem
		text-align center
		text-wrap balance
	.mt-description
		margin-top 16px

.btn-description
	color $black
	margin-top 4px
	margin-inline auto
	font-size 1.2rem
	text-align center
	width 80%


.footer
	width 100%
	display flex
	flex-wrap wrap
	justify-content center
	gap 16px
	margin-top 16px
	margin-bottom 8px
	.btn
		height  fit-content
		padding-block 8px 
		width 272px
		font-size 16px

.horizontal-line
	width 100%
	height 2px
	margin-bottom 9px
	border none
	background-color blue

.modal-open-lottery
	flex center
	> .bg
		absolute 0
</style>