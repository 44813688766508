<template>
	<div class="modal-ask-audio">
		<div class="bg"></div>
		<div class="content">
			<div class="title">Son & musique</div>
			<div class="question">
				<p>Aimerais-tu jouer avec de la musique, des effets sonores et de la narration?</p>
				<p>Tu pourras modifier ta préférence depuis les paramètres du jeu.</p>
				<p><ui-icon name="audio-on" :size="48" /></p>
			</div>
			<div class="answers">
				<ui-button data-id="sounds-off-btn" color="red" block="true" v-tap="() => answer('no')">Non <ui-icon name="audio-off" :size="24" /></ui-button>
				<ui-button data-id="sounds-on-btn" color="green" block="true" v-tap="() => answer('yes')">Oui <ui-icon name="audio-on" :size="24" /></ui-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		popup: { default: null }
	},
	computed: {
		...mapWritableState(useAppStore, [ 'settings' ]),
	},
	methods: {
		answer(answer) {
			localStorage.setItem('activeAudio', answer)
			if (answer === 'no') {
				this.settings.sounds.volume = 0
				this.settings.musics.volume = 0
				this.settings.voices.volume = 0
				this.settings.sounds.muted = true
				this.settings.musics.muted = true
				this.settings.voices.muted = true
			}
			this.close()
		},
		close() {
			this.$emit('close')
		}
	},
}
</script>

<style lang="stylus" scoped>

.modal-ask-audio
	absolute 0
	flex center
	.bg
		absolute 0
		background-color alpha(dark-blue, 75%)
	> .content
		width calc(100vw - 32px)
		max-width 520px
		padding 32px
		flex center column
		gap 48px
		// background-color #eee
		border-radius 8px
		color #fff
		font-size 2rem
		text-align center
		.title
			width 100%
			margin 0 0 16px 0
			font-size 3.2rem
			border-bottom 3px solid blue
		.question
			width 100%
			flex center column
			gap 16px
			> p
				width 100%
				strong
					font-weight 500
		.answers
			width 100%
			flex center
			gap 16px
			.ui-button
				flex center


</style>
