<template>
	<div class="modal-open-suitcase" @click.stop>
		<div class="bg" v-tap="close"></div>
		<div class="suitcase">
			<div class="bg" v-bg="'discoveries/open-suitcase.svg'"></div>

			<div class="items">
				<suitcase-item v-for="item in items" :key="item.id" v-bind="item" v-tap="() => selectItem(item)" @click.stop />
			</div>

			<div class="top-cache" v-bg="'discoveries/open-suitcase-cache.svg'"></div>

		</div>
	</div>
</template>

<script>
export default {
	props: {
		modal: { default: null, required: true }
	},
	computed: {
		...mapState(useAppStore, [ 'modals' ]),
		suitcase() {
			return this.modal?.suitcase
		},
		items() {
			return this.suitcase?.items || []
		}
	},
	watch: {
		'modals.length'(n) {
			if (n === 1) {
				[ ...this.$el.querySelectorAll('.suitcase-item') ].forEach($el => {
					resetElementToCenterOfScreen({ $el })
				})
			}
		}
	},
	methods: {
		...mapActions(useAppStore, [ 'addModal' ]),
		close() {
			this.$emit('close')
		},

		async selectItem(item){
			if(!item) return
			
			const actions = {
				'link': async (item) => await this.openLink(item),
				'open': async (item) => await this.openItem(item)
			}

			const action = actions[item.type] ?? actions['open']
			await action(item)
		},

		async openLink(item){
			window.open(item.link, "_blank");
		},

		async openItem(item) {
			const $el = this.$el.querySelector('#item-' + item.id)
			await moveElementToCenterOfScreen({ $el, scale: 1, zIndex: 10, delay: 500 })
			this.addModal({ slug: 'open-suitcase-item', background: 'dark-blue', item, scrollable: false })
		}
	},
}
</script>

<style lang="stylus" scoped>

#tablet-vertical
	.modal-open-suitcase
		.suitcase
			height round(816px / 1.5)
			width round(669px / 1.5)
			
#mobile
	.modal-open-suitcase
		.suitcase
			height round(816px / 2)
			width round(669px / 2)
			
.modal-open-suitcase
	absolute 0
	flex center
	> .bg
		absolute 0
	.suitcase
		height (816px / 1)
		width (669px / 1)
		// aspect-ratio 1.220
		> .bg
			absolute 0
			background center center no-repeat
			background-size contain
		> .top-cache
			position absolute
			left 13%
			top 27.5%
			// width (496px)
			width 74%
			aspect-ratio 4.509
			background center center no-repeat
			background-size contain
			z-index 3
			pointer-events none
		> .items
			absolute 0
			

</style>