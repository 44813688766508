export default {
	viewMore: 'View more',
	next: 'Next',
	close: 'Close',

	start: {
		loginButton: 'Login',
		signupButton: 'Create an account',
		continueButton: 'Continue',
		login: {
			title: 'Login',
			emailLabel: 'E-mail address',
			emailPlaceholder: 'Your e-mail address',
			passwordLabel: 'Password',
			passwordPlaceholder: 'Your password',
			forgotPasswordLink: 'I forgot my password',
			backButton: 'Back',
			submitButton: 'Confirm'
		},
		signup: {
			title: 'Create an account',
			firstnameLabel: 'First name',
			firstnamePlaceholder: 'Your firstname',
			emailLabel: 'Your e-mail address',
			emailPlaceholder: 'Your e-mail address',
			passwordLabel: 'Password',
			passwordPlaceholder: 'Your password',
			passwordConfirmationLabel: 'Repeat password',
			passwordConfirmationPlaceholder: 'Your password again',
			disclaimer1: '*Disclaimer*',
			disclaimer2: 'Your personal information will not be made public',
			backButton: 'Back',
			submitButton: 'Confirm',
			successText: 'An email has been sent to {email} to confirm your registration.',
			errorText: 'Unable to create a account with this informations.',
		},
		forgot: {
			emailLabel: 'E-mail address',
			emailPlaceholder: 'Your e-mail address',
			backButton: 'Back',
			submitButton: 'Send',
			successText: 'An email has been sent to {email} to change your password.',
			errorText: 'Unable to send an e-mail to this address.',
		},
	},

	



	tuto: {
		nextButton: 'Next',
	},
	
	modalLogout: {
		title: 'Are you sure you want to log out?',
		buttonCancel: 'Do not log out',
		buttonSubmit: 'Log out',
	},
	errorModal: {
		title: 'Something went wrong.'
	}
}
