<template>
	<div class="modal-embed-video">
		<div class="bg"></div>
		<iframe width="95%" height="95%" :src="modal.url" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
	</div>
</template>

<script>
export default {
	props: {
		modal: { default: null }
	},
}
</script>

<style lang="stylus" scoped>

.modal-embed-video
	absolute 0
	flex center
	> .bg
		absolute 0
		background-color alpha(#000, 50%)
		
	
</style>
