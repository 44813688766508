export const startGame = async ({ id }) => {
	const response = await post('startGame', { gameId: id })
	if (response?.id) {
		return response
	}
	return null
}

export const sendAnswer = async ({ progressionId, questionId, answers }) => {
	return await post('sendAnswer', { progressionId, questionId, answers })
}

export const sendMatch = async ({ progressionId, matchableElementIds }) => {
	return await post('sendMatch', { progressionId, matchableElementIds })
}

export const sendScramble = async ({ progressionId, sentenceId, answers }) => {
	return await post('sendScramble', { progressionId, sentenceId, answers })
}

export const nextStep = async ({ progressionId, target = null }) => {
	return await post('completeStep', { progressionId, target })
}

export const submitForm = async ({ progressionId, answers }) => {
	return await postThrowError('submitForm', { progressionId, answers })
}

export const goToNextMonth = async () => {
	return await post('nextMonth')
}

export const openDiscovery = async ({ fileId }) => {
	return await post('docuDecouverte', { clientFileId: fileId })
}

export const endItinerary = async ({ fileId }) => {
	return await post('finItineraire', { clientFileId: fileId })
}
