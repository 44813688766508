export default {
	viewMore: 'Voir plus',
	next: 'Suivant',
	close: 'Fermer',
	submit: {
		error: 'Une erreur s\'est produite. Veuillez réessayer ultérieurement. Si le problème persiste, veuillez contacter le support technique.'
	},
	start: {
		loginButton: 'Connexion',
		signupButton: 'Créer un compte',
		continueButton: 'Continuer',
		login: {
			title: 'Connexion',
			emailLabel: 'Courriel',
			emailPlaceholder: 'Votre courriel',
			passwordLabel: 'Mot de passe',
			passwordPlaceholder: 'Votre mot de passe',
			forgotPasswordLink: 'Mot de passe oublié',
			backButton: 'Retour',
			submitButton: 'Connexion'
		},

		signup: {
			title: 'Création de compte',
			firstnameLabel: 'Surnom',
			firstnamePlaceholder: 'Votre surnom',
			emailLabel: 'Courriel',
			emailPlaceholder: 'Votre courriel',
			passwordLabel: 'Mot de passe',
			passwordPlaceholder: 'Votre mot de passe',
			passwordConfirmationLabel: 'Mot de passe (confirmation)',
			passwordConfirmationPlaceholder: 'Votre mot de passe à nouveau',
			backButton: 'Retour',
			submitButton: 'Confirmer',
			successText: "Un courriel a été envoyé à l'adresse {email} pour confirmer votre inscription.",
			errorText: 'Impossible de créer un compte avec ces informations.',
		},
		forgot: {
			title: 'Mot de passe oublié',
			emailLabel: 'Courriel',
			emailPlaceholder: 'Votre courriel',
			backButton: 'Retour',
			submitButton: 'Envoyer',
			successText: "Un courriel a été envoyé à l'adresse {email} pour modifier votre mot de passe.",
			errorText: "Impossible d'envoyer un courriel à cette adresse.",
		},
	},
	teacherPanel: {
		activities: 'Activités pédagogiques',
		resources: 'Ressources',
		about: 'À propos',
		contact: 'Contactez-nous',
		partners: 'Partenaires',
	},
	tuto: {
	
	},
	difficulties: {
		debutant: {
			title: 'Excursion',
			titleLowerCase: 'excursion',
			subtitle: 'Débutant',
			voiceSlug: 'excursion'
		},
		intermediaire: {
			title: 'Expédition',
			titleLowerCase: 'expédition',
			subtitle: 'Intermédiaire',
			voiceSlug: 'expedition'
		},
		avance: {
			title: 'Aventure',
			titleLowerCase: 'aventure',
			subtitle: 'Avancé',
			voiceSlug: 'aventure'
		},
	},
	trivia: {
		feedbackCorrectTitle: 'Correct!',
		feedbackDefaultText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in orci id metus placerat placerat.',
		feedbackNextButton: 'Suivant',
	},
	resetProgress: {
		title: 'EFFACER MA PROGRESSION',
		subtitle: 'Veux-tu effacer ta progression?',
		message: 'Tes récompenses, tes rencontres et tes docu-découvertes seront supprimés définitivement.',
		btnNo: 'Non, je conserve ma progression',
		btnYes: 'Oui, je veux supprimer ma progression'
	},
	matching: {
		feedbackCorrectTitle: 'Correct!',
		feedbackDefaultText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in orci id metus placerat placerat.',
		feedbackNextButton: 'Suivant',
	},
	modalLogout: {
		title: 'Êtes-vous sûr.e de vouloir vous déconnecter?',
		buttonCancel: 'Ne pas se déconnecter',
		buttonSubmit: 'Se déconnecter',
	},
	errorModal: {
		title: "Quelque chose s'est mal passé."
	},
	serverErrors: {
		'email_not_available': "Le courriel n'est pas disponible ou est déjà utilisé.",
		'user_is_already_taken': "Le nom d'usager n'est pas disponible.",
		'invalid_informations': 'Informations invalides.',
		'login_student_error' : 'Informations invalides.',
		'create_student_error' : 'Informations invalides.',
		'email_not_confirmed': "Un courriel contenant un lien de validation a été envoyé à l'adresse {email}. Il sera possible de recevoir un nouveau courriel de validation plus tard.",
	},
	resendEmailConfirmationText: "Un nouveau courriel de validation a été envoyé à l'adresse {email}",
	emailNotConfirmedResendIt: "Quelque chose s'est mal passé.<br> Ton adresse courriel n'a pas été validée. Un courriel contenant un lien de validation t'a été envoyé lors de la création de ton compte. Souhaites-tu le recevoir à nouveau?",
	forwardEmailAction: 'Renvoyer le courriel'
}
