import { cloneDeep } from "@/js/utils"

const DEFAULT_SETTINGS = {
	lang: 'fr',
	sounds: {
		volume: 5,
		muted: false,
	},
	musics: {
		volume: 3,
		muted: false,
	},
	voices: {
		volume: 5,
		muted: false,
	}
}
export const useAppStore = defineStore({
	id: 'app',

	state: () => ({
		ready: false,
		loading: false,
		device: 'desktop',
		modals: [],
		notifications: [],
		setUpClient: null,
		mainSection: null,
		settings: cloneDeep(DEFAULT_SETTINGS),
		hasSeenTeacherPanelAnimation: true,
	}),
	
	actions: {
		async initApp() {
			try {
				this.loadSettings()
				initMusics()
				initSounds()
	
				await Promise.all([
					loadMedias(),
					this.loadSetUpClient()
				])
			} catch(err) {
				console.error(err)
			} finally {
				this.ready = true
			}
		},
		resetSettings() {
			localStorage?.removeItem?.('settings')
			this.settings = cloneDeep(DEFAULT_SETTINGS)
		},
		// To avoid losing the legacy version of the settings. Verifies if legacy settings exist and migrates them.
		loadSettings() {
			let settings = parseJson(localStorage.getItem('settings')) ?? this.settings

			if (settings.musics) {
				this.settings = settings
			} else {
				settings = {
					...this.settings,
					...settings,
				}
	
				if (isValue(settings.volumeSounds)) settings.sounds.volume = settings.volumeSounds
				if (isValue(settings.volumeMusics)) settings.musics.volume = settings.volumeMusics
				if (isValue(settings.volumeVoices)) settings.voices.volume = settings.volumeVoices
	
				delete settings.volumeSounds
				delete settings.volumeMusics
				delete settings.volumeVoices
	
				this.settings = settings
			}
		},

		setMainSection(mainSection){
			this.mainSection = mainSection
		},

		async loadMainSectionIfNull() {
			this.loading = true
			this.mainSection = this.mainSection ?? await mainSection()
			this.loading = false
		},

		async loadSetUpClient(){
			this.setUpClient = await setUpClient()
		},

		setRatio({ width, height }) {
			if (width < 600) this.device = 'mobile'
			else if (width < 900 || height < 830) this.device = 'tablet-vertical'
			else if (width < 1200) this.device = 'tablet-horizontal'
			else this.device = 'desktop'
		},

		addModal(data) {
			if (data.slug) this.modals.push({ ...data, id: getRandomID(), on: {
				...data.on,
				close: () => this.removeModal({ id: data.id }),
			} })
		},

		removeModal({ id }) {
			this.modals = this.modals.filter(n => n.id !== id)
		},

		removeAllModals() {
			this.modals = []
		},
	},

})


if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}