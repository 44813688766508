<template>
	<div class="modal-announcement">
		<div class="bg" v-tap="close"></div>
		<div class="content">
			<div class="title">Par ici les nouveautés!</div>
			<div class="texts">
				<p><strong>Grâce à vos commentaires, vous pouvez maintenant:</strong></p>
				<ul>
					<li>Découvrir le premier docu-découvertes sur le sujet des Premiers Peuples;</li>
					<li>Entendre la voix de Sasha, votre partenaire de voyage;</li>
					<li>Expérimenter le niveau «aventure»;</li>
					<li>Explorer la zone pédagogique;</li>
					<li>Vivre une expérience visuelle et sonore bonifiée (animation des personnages, photos, nouvelle trame sonore);</li>
				</ul>
				<p>Bonne découverte!</p>
				<p>Abonnez-vous à notre <a href="https://app.dialoginsight.com/T/WF/21229/TUw46o/Optin/fr-CA/Form.ofsys" target="blank">infolettre</a> et notre <a href="https://www.facebook.com/lioACELF" target="blank">page Facebook</a>!</p>
			</div>
		</div>
		<div class="buttons">
			<ui-button color="green" v-tap="close">Fermer</ui-button>
		</div>
	</div>
</template>
<script>
export default {
	methods: {
		close() {
			this.$emit('close')
		}
	},
	beforeUnmount() {
		localStorage.setItem('viewed-announcement-1', true)
	}
}
</script>

<style lang="stylus" scoped>

.modal-announcement
	display block
	.bg
		absolute 0
	> .content
		width calc(100vw - 32px)
		max-width 500px
		padding 32px
		flex center column
		gap 32px
		background-color #eee
		border-radius 8px
		color dark
		font-size 1.8rem
		text-align center
		.title
			width 100%
			padding-bottom 16px
			border-bottom 3px solid blue
			font-size 2.8rem
			line-height 1.2em
			font-weight 500
			text-transform uppercase
		.texts
			p
				margin 0 0 16px 0
				strong
					font-weight 600
				a
					color inherit
					text-decoration underline
					&:hover
						text-decoration none
			ul
				text-align left
				margin 0 0 8px 24px
				li
					margin 0 0 8px 0
					&:last-child
						margin-bottom 0
	> .buttons
		width 100%
		flex center
		padding 32px 0 0 0
		gap 16px
		.ui-button
			text-transform uppercase

</style>