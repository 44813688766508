import { engage } from '@/engage'

export const error = (e) => {
	console.error(e)
}

export const get = async (name, variables = {}, headers = {}) => {
	return (await engage.boxGet(name, { variables, headers })).fold(error, (x) => x)
}

export const batchGet = async (name, variables = {}, params = {}, headers = {}) => {
	return (await engage.batchGet(name, { variables, headers, params })).fold(error, (x) => x)
}

export const post = async (name, variables = {}, headers = {}, onError = error) => {
	return (await engage.boxPost(name, { variables, headers })).fold(onError, (x) => x)
}

export const postThrowError = async (name, variables = {}, headers = {}) => {
	return (await engage.boxPost(name, { variables, headers })).fold(e => {throw e}, (x) => x)
}
