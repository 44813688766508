<template>
	<button :type="type" :disabled="disabled"
		class="ui-button"
		:class="[ color, shape, size, { loading, block, selected, 'is-new': isNew, 'auto-height': autoHeight } ]"
		v-tap="click"
	>
		<slot />
		<span v-if="loading" class="loader">
			<ui-loader color="color" :size="40" />
		</span>
		<ui-new-indicator v-if="isNew" />
	</button>
</template>

<script>
export default {
	props: {
		type: { default: 'button' },
		shape: { default: '' },
		size: { default: '' },
		color: { default: 'blue' },
		selected: { default: false },
		disabled: { default: false },
		isNew: { default: false },
		loading: { default: false },
		block: { default: false },
		autoHeight: { default: false },
	},
	methods: {
		click() {
			if (!this.disabled) playSound({ slug: 'click' })
		}
	}
}
</script>

<style lang="stylus" scoped>

.ui-button
	position relative
	height 56px
	padding 0 40px
	inline-flex center
	gap 8px
	border none
	border-radius 32px
	transition 0.1s easeOutQuart
	outline none
	cursor pointer
	font inherit
	font-family Acumin
	font-size 2rem
	line-height 1em
	font-weight 500
	color #fff
	user-select none
	transform-origin bottom center
	box-shadow inset 0 -4px 0 0 alpha(#000, 25%)
		
	&.round
		padding 0
		width 64px
		flex center
		min-width auto
	
	&.block
		display block
		width 100%
		padding 0 16px
	
	&.small
		height 40px
		padding 0 24px
		font-size 1.8rem
		line-height 1em
		border-radius 24px
	
	&.auto-height
		height auto
		padding-top 12px
		padding-bottom 12px
		min-height 64px

	&.slim
		height auto
		
	&.is-new
		box-shadow 0 0 0 4px #CF751C
		animation pulse 1s easeInQuart infinite alternate
		@keyframes pulse
			0%
				box-shadow 0 0 0 4px #CF751C
			100%
				box-shadow 0 0 4px 6px #CF751C
		.ui-new-indicator
			right -12px
			top -12px

	&.orange
		background-color #C1701A
	&.gray
		background-color #525868
	&.blue
		background-color darken(blue, 20%)
	&.light-blue
		background-color light-blue
		color dark
	&.dark-blue
		background-color dark-blue
	&.red
		background-color red
	&.green
		background-color darken(green, 35%)
	&.dark-green
		background-color #09444E
	&.dark
		background-color dark
	&.yellow
		background-color #FBB53B
	&.dark-yellow
		background-color #C28E34

	&:not([disabled]):not(.loading)
		&:hover
			box-shadow inset 0 -4px alpha(#000, 35%)
			&:after
				content ''
				absolute 0
				background linear-gradient(to top, alpha(#fff, 0%), alpha(#fff, 5%), alpha(#fff, 5%))
				border-radius @border-radius
		&:active
			transform translateY(3px)
			box-shadow inset 0 -1px alpha(#000, 50%)
			&:after
				content ''
				absolute 0
				background linear-gradient(to top, alpha(#000, 0%), alpha(#000, 14%), alpha(#000, 14%))
				border-radius @border-radius
	
	&[disabled]
		cursor not-allowed
		opacity 0.5
			
	.loader
		absolute 6px
		padding-bottom 4px
		flex center
		cursor wait
		background-color inherit
		border-radius inherit
	
	::v-deep(.ui-icon)
		transform translateY(-2px)
		
</style>