<template>
	<div class="modal-reset-progress">
		<div class="bg" v-tap="close"></div>
		<div class="content">
			<div class="title">{{ $t('resetProgress.title') }}</div>
			<div class="question">
				<p><strong>{{ $t('resetProgress.subtitle') }}</strong></p>
				<p>{{ $t('resetProgress.message') }}</p>
			</div>
			<div class="answers">
				<ui-button data-id="close-btn" color="green" block="true" v-tap="close">{{ $t('resetProgress.btnNo') }}</ui-button>
				<ui-button data-id="confirm-btn" color="red" block="true" v-tap="resetProgress">{{ $t('resetProgress.btnYes') }}</ui-button>
			</div>
		</div>
	</div>
</template>
<script setup>
import { useResetProgress } from '@/composable/reset-progress';

const emit = defineEmits(['close'])

const { resetProgress } = useResetProgress()

const close = () => emit('close')
</script>

<style lang="stylus" scoped>

.modal-reset-progress
	flex center
	width 620px !important
	.bg
		absolute 0
	> .content
		color dark
		width calc(100vw - 32px)
		max-width 620px
		padding 32px
		padding-top 12px
		flex center column
		gap 24px
		background-color #eee
		border-radius 24px
		font-size 2rem
		text-align center
		.title
			width 100%
			font-size 3.2rem
			border-bottom 3px solid blue
		.question
			width 100%
			flex center column
			gap 16px
			> p
				width 100%
				strong
					font-weight 500
		.answers
			width 100%
			flex center
			flex-wrap wrap
			gap 16px
		.ui-button
			max-width 270px !important
			min-width 120px !important
</style>
