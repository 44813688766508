<template>
	<div class="modal-concours">
		<div class="bg" v-tap="close"></div>
		<div class="content">
			<div class="title">Concours</div>
			<div class="texts">
				<p>Vous êtes membre du personnel enseignant?</p>
				<p>Faites l’expérience de l’aventure Lio avec vos élèves et partagez vos commentaires et suggestions avec nous!</p>
				<p>Participez au concours du 30 mars au 30 avril en remplissant notre <a href="https://ij3qzaim2el.typeform.com/Mission4-Lio" target="blank">sondage</a>.</p>
				<p>40 coffrets-découvertes Lio d’une valeur de 175&nbsp;$ à remporter!</p>
			</div>
		</div>
		<div class="buttons">
			<ui-button color="green" v-tap="participate">Participer</ui-button>
		</div>
	</div>
</template>
<script>
export default {
	methods: {
		participate() {
			window.open('https://ij3qzaim2el.typeform.com/Mission4-Lio', '_blank').focus()
		},
		close() {
			this.$emit('close')
		}
	}
}
</script>

<style lang="stylus" scoped>

.modal-concours
	display block
	.bg
		absolute 0
	> .content
		width calc(100vw - 32px)
		max-width 500px
		padding 32px
		flex center column
		gap 32px
		background-color #eee
		border-radius 8px
		color dark
		font-size 2rem
		text-align center
		.title
			width 100%
			padding-bottom 16px
			border-bottom 3px solid blue
			font-size 3.2rem
			font-weight 500
			text-transform uppercase
		.texts
			p
				margin 0 0 16px 0
				strong
					font-weight 600
				a
					color inherit
					text-decoration underline
					&:hover
						text-decoration none
	> .buttons
		width 100%
		flex center
		padding 32px 0 0 0
		gap 16px
		.ui-button
			text-transform uppercase

</style>