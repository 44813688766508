import { getSuitcases } from '@/helpers/suitcase-helper'
import { walkThrough } from '@affordancestudio/functions'
import { useMapStore } from './map-store'
import { medias } from '@affordancestudio/engage-game'
import {getLootItemService} from '@/services/section-bg-service'

export const useGroupStore = defineStore({
	id: 'group',

	state: () => ({
		myGroups: {},
		currentCategoryId: null,
		currentGroupDisplayId: '',
		currentGroupDisplay: {},
		currentGroupId: '',
		currentGroupName: '',
		selectedMemberId: null,
		selectedMemberUsername: null,
		newPasswordResponse: '',
		currentMemberStats: '',
		currentMemberInfo: '',
		currentDocuDecouverteByCategory: null,
		currentCommentsByElement: [],
		currentGamesByAlternative: {},
		docuDecouverteByCategoryTag: '{"value": "docu-decouverte"}',
		itineraryTag: '{"value": "character-badge"}', // TODO: à enlever ou pas - voir avec le backend
		currentItinerary: {},
		selectedDifficulty: 'debutant',
		sections: [],
	}),
	
	getters: {

		categories(){
			const source = useMapStore().categories
			return (source?.data ?? []).map(({id, document, clientMedias}) => ({id, title: document?.title, clientMedias}))
		},

		currentCategory(){
			return this.categories.find(c => c.id === this.currentCategoryId)
		},

		students(){
			return this.currentGroupDisplay?.memberships?.data?.map(({clientUser}) => ({...clientUser})) ?? []
		},

		currentStudent(){
			return this.students.find(s => s.id === this.selectedMemberId)
		},

		characters(){
			return (this.currentItinerary?.clientFiles ?? []).map( f => ({...f, illustration: medias.get({ id: f?.clientMedias?.[0]?.id })}))
		},

		areas(){
			return this.currentItinerary?.clientGames ?? []
		},

		journal(){
			const displayJournal =
				this.sections.length
				&& this.areas.length
				&& JSON.stringify(this.currentGroupDisplay) !== '{}'
				&& JSON.stringify(this.currentItinerary) !== '{}'

			const lootItemService = getLootItemService(this.currentCategory)

			const student = this.currentStudent
			const characters = this.characters
			const getStatus = (achievementId, studentFiles) => studentFiles?.find(a => a?.fileId === achievementId)?.status
			const filterAlternative = (alternative) => (games) => games?.alternatives?.includes(alternative)
			const areaMap = (areas, data) => {
				const section = data?.clientSections?.[0]
				const areaId = section?.id
				let area = areas.find(a => a.id === areaId)

				if(area == null){
					area = {
						id: areaId,
						document: section?.document,
						clientGames: [],
						hideJournal: !displayJournal
					}
					areas.push(area)
				}
				
				if (student) {
					let stamp = student?.inventory?.find(i => i.clientLootItem?.clientRewards?.find(r => r.rewardableElementId === data.id))
					const isCompleted = student?.achievement?.gameJournal?.find(x => x.gameId === data.id)?.status === 'completed'
					const defaultMediaId = this.areas.find(x => x.id === data.id)?.clientRewards?.[0]?.media_id
					const sectionName = section?.document?.tag?.toLowerCase()
					const lootItem = lootItemService.findBySectionName(sectionName)
					
					const media_id = isCompleted 
						? stamp?.clientLootItem?.clientMediaIds?.[0] ?? defaultMediaId 
						: null

					area.clientGames.push({
						id: data.id,
						document: data.document,
						stamp,
						status: stamp ? 'completed' : 'incomplete',
						nbStars: stamp?.amount,
						type: lootItem?.type,
						showStars: isCompleted,
						backgroundImage: lootItem ? medias.get({ id: lootItem.media_id })?.base64 : '',
						reward: {
							media_id,
							...stamp,
							tags: stamp?.clientLootItem?.tags
						}
					})
				}

				return areas
			}

			return {
				shouldStartTuto: false,
				characters: characters.map(c => ({
					...c,
					status: getStatus(c.id, student?.achievement?.fileJournal)
				})),
				areas: this.areas.filter(filterAlternative(this.selectedDifficulty)).reduce(areaMap, []),
				startGameActivatedInMemories: false
			}
		}
	},

	actions: {
		async loadSections() {
			this.sections = (await myMap({ category: JSON.stringify({value: this.currentCategoryId}) })) ?? []
		},
		async loadMyGroups() {
			this.myGroups = await myGroups({})
		},

		async loadGroupDisplay(id, page = 1) {
			const result = await groupDisplay({ id, page })
			
			if (page > 1) {
				if (this?.currentGroupDisplay?.memberships?.data) {
					this.currentGroupDisplay.memberships.data = [
						...(this?.currentGroupDisplay?.memberships?.data ?? []),
						...(result?.memberships?.data ?? [])
					]
				}
			} else {
				this.currentGroupDisplay = result
			}
			this.currentGroupDisplayId = id
		},
		
		async createGroup(name) {
			await createGroup({ name })
			this.currentGroup = name
		},
		
		async deleteGroup() {
			await deleteGroup({ id: this.currentGroupId })
		},
		
		setCurrentGroupName(name) {
			this.currentGroupName = name
		},
		
		setCurrentGroupId(id) {
			this.currentGroupId = id
		},
		
		setSelectedMemberUsername(username) {
			this.selectedMemberUsername = username
		},
		
		setSelectedMemberId(id) {
			this.selectedMemberId = id
		},

		async loadGamesByAlternative() {
			this.currentGamesByAlternative = await gamesByAlternative()
		},

		async loadDocuDecouverteByCategory(categoryId, groupId, tag) {
			this.currentDocuDecouverteByCategory = await docuDecouverteByCategory({
				category: JSON.stringify({value: categoryId}),
				groupId,
				tag
			})
		},

		async loadCommentsByElement(elementId, groupId, page) {
			this.currentCommentsByElement = await commentsByElement({ elementId, groupId, page })
		},

		async setItinerary(categoryId, tag) {
			this.currentItinerary = {}
			this.currentItinerary = await setItinerary({ category: JSON.stringify({value: categoryId}), tag })
		},

		async loadMemberStats(memberIds, category) {
			this.currentMemberStats = await memberStats({ memberIds, category })
		},

		async deleteCurrentMember() {
			const result = await deleteMember({ userId: this.selectedMemberId })
			
			if (result) {
				this.currentGroupDisplay.memberships.data = this.currentGroupDisplay.memberships.data.filter(x => x.clientUser.id !== this.selectedMemberId)
			}
			
			await this.loadGroupDisplay(this.currentGroupDisplayId)
			await this.loadMyGroups()
		},

		async editCurrentMember(userName, firstName) {
			const userId = this.selectedMemberId
			this.currentMemberInfo = await editMember({
				userId,
				userName,
				firstName,
			})
			
			if (userName || firstName) {
				for (let x of this.currentGroupDisplay.memberships.data) {
					if (x.clientUser.id === userId) {
						Object.assign(x.clientUser, {
							firstName,
							userName,
						})
					}
				}
			}
		},

		async resetMemberPassword(groupId, memberId) {
			const response = await resetMemberPassword({ groupId, memberId })
			this.resetPasswordResponse = response
		},


		getSuitcaseById(suitcaseId){
			const getLootItems = ({value, memory}) => {
				value && memory.push(value['clientLootItem'])
				return memory
			}

			const hasClientLootItem = ({value}) => value?.['clientLootItem']

			const selectedData = (this.currentDocuDecouverteByCategory?.data ?? []).filter(d => d.id === suitcaseId)

			const lootItems = walkThrough({
				condition: hasClientLootItem,
				action: getLootItems,
				value: selectedData,
				memory: []
			})
		
			return getSuitcases(lootItems)?.[0] ?? {}
		},
	}

})

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useGroupStore, import.meta.hot))
}
