import Hammer from 'hammerjs'

const TouchEvents = {}

const events = [ 'tap', 'pan', 'panstart', 'panend', 'press', 'pressup' ]

TouchEvents.install = function (Vue, opts) {
	if (opts && opts.events) {
		opts.events.forEach((eventName) => {
			if (events.includes(eventName)) {
				Vue.directive(eventName, {
					beforeMount (el, binding) {
						el.hammer = new Hammer(el)
						el.hammer.on(eventName, e => binding.value(e))
						el.hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL })
					},
					beforeUnmount (el) {
						if (el.hammer) el.hammer.destroy()
						el.hammer = null
					}
				})
			} else {
				console.warn('Touch event \'' + eventName + '\' is not recognized. Authorized events: tap, pan, panstart, panend, press, pressup.')
			}
		})
	}
}

export default TouchEvents
