<template>
	<div class="modal-delete-group">

		<div class="title">Supprimer un groupe</div>

		<div class="content">
			<div class="intro-text">Êtes vous sûr de vouloir supprimer ce groupe?</div>
			<div class="group">{{ currentGroup }}</div>
			<div>Les comptes de jeu associés seront <br>également supprimés.</div>
		</div>
		
		<div class="btn-wrap">
			<ui-button class="ui-btn" color="red" v-tap="close">Annuler</ui-button>
			<ui-button class="ui-btn" color="green" @click="deleteCurrentGroup()">Supprimer le groupe</ui-button>
		</div>

	</div>
</template>


<script>
export default {
	emits: [ 'close' ],
	computed: {
		...mapState(useGroupStore, ['currentGroupName']),

		currentGroup() {
			return this.currentGroupName
		},
	},
	methods: {
		...mapActions(useGroupStore, ['deleteGroup', 'loadMyGroups']),
		close() {
			this.$emit('close')
		},
		async deleteCurrentGroup() {
			await this.deleteGroup()
			await this.loadMyGroups()
			this.close()
		},
	},
	mounted() {
		this.loadMyGroups()
	}
}
</script>


<style lang="stylus" scoped>
	.modal-delete-group
		max-width 500px
		background-color #fff
		padding 16px 24px 24px 24px
		border-radius 16px
		display flex
		flex-direction column
		gap 16px
		text-align center
		.title
			border-bottom 2px solid #3B4851
			padding-bottom 8px
			font-size 2rem
			font-weight 500
			text-transform uppercase
			text-align center
		.content
			display flex
			flex-direction column
			gap 8px
			font-size 1.6rem
			padding-bottom 8px
			.intro-text
				text-transform uppercase
				font-weight 500
			.group
				font-size 3rem
				font-weight 500
				color #3B4851
		.btn-wrap
			display flex
			gap 16px
			.ui-btn
				height auto
				padding 8px
				width calc(100% - 48px)
				margin 0 auto
				font-size 1.8rem

	#mobile
		.ui-btn
			line-height 1.2em

</style>