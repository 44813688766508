<template>
	<div class="open-phone" @click.stop>
		<div class="illustration" v-bg="bgImage"></div>
		<div class="content">
			<div class="slider" v-panstart="panstart" v-pan="pan" v-panend="panend">
				<div class="slides" ref="slides" :data-current="currentSlideIndex">
					<div v-for="slide in slides" :key="slide.id" class="slide" :class="{ 'is-video': slide.image?.document?.url }" @click="tapSlide(slide)" @click.stop>
						<img :src="slide.image?.base64" :alt="slide.image?.document?.title || ''">
						<div class="play" v-bg="'play.svg'"></div>
					</div>
				</div>
			</div>

			<div class="arrows">
				<div class="arrow prev" :class="{ disabled: currentSlideIndex === 0 }" v-tap="prev" @click.stop></div>
				<div class="arrow next" :class="{ disabled: currentSlideIndex === slides.length - 1 }" v-tap="next" @click.stop></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			translateXBeforePan: 0,
			currentSlideIndex: 0,
		}
	},
	props: {
		bgImage: {required: true, type: String},
		slides: {required: true, type: Array, validator(value, props){
			return value
		}},
	},
	methods: {
		...mapActions(useAppStore, [ 'addModal' ]),
		panstart() {
			const style = window.getComputedStyle(this.$refs.slides)
			const matrix = style.transform || style.webkitTransform || style.mozTransform
			this.translateXBeforePan = parseInt(matrix?.split(', ')?.[4] || 0)
		},
		pan(e) {
			this.$refs.slides.style.transform = 'translateX(' + (e.deltaX + this.translateXBeforePan) + 'px)'
		},
		panend(e) {
			if (e.deltaX > 50) this.prev()
			else if (e.deltaX < -50) this.next()

			this.translateXBeforePan = 0
			this.$refs.slides.style.transform = ''
		},
		gotoSlide(n) {
			this.currentSlideIndex = minmax(n, 0, this.slides.length - 1)
		},
		prev() {
			this.gotoSlide(this.currentSlideIndex - 1)
		},
		next() {
			this.gotoSlide(this.currentSlideIndex + 1)
		},
		tapSlide(slide) {
			if (slide.image?.document?.url) {
				this.addModal({ slug: 'embed-video', url: slide.image.document.url, scrollable: false, background: 'dark' })
			}
		}
	},
}
</script>

<style lang="stylus" scoped>

#mobile
	.open-phone
		max-width 300px
		max-height 593px
		.illustration
			background-size 300px 593px
		.content
			slideWidth = (300px - 32px)
			slideGap = 8px
			.slides
				for i in 1..20
					&[data-current=\"{i}\"]
						transform 'translateX(-%s)' % ((slideWidth + slideGap) * i)
				.slide
					width slideWidth
			.arrows
				top calc(100% + 18px)
				height 32px
				padding 0 64px

.open-phone
	width 100%
	height 100%
	max-width 420px
	max-height 833px

	.illustration
		absolute 0
		background center center no-repeat
		background-size 420px 833px

	.content
		absolute 16px
		top 64px
		bottom 64px
		slideWidth = round(420 - 32px)
		slideGap = 8px
		.slider
			height 100%
			overflow hidden
			.slides
				height 100%
				flex start
				gap slideGap
				transition 0.25s easeOutQuart
				for i in 1..20
					&[data-current=\"{i}\"]
						transform 'translateX(-%s)' % ((slideWidth + slideGap) * i)
				.slide
					height 100%
					width slideWidth
					flex center
					flex-shrink 0
					img
						width 100%
						height auto
					&.is-video
						cursor pointer
						&:hover
							.play
								transform scale(1.1)
						&:active
							.play
								transform scale(1)
						.play
							position absolute
							left calc(50% - 32px)
							top calc(50% - 32px)
							width 64px
							height 64px
							background red left 10px center no-repeat
							background-size 48px
							border-radius 50%
							box-shadow 0 0 24px 12px #000
							transition 0.15s easeOutBack
		.arrows
			position absolute
			left 0
			top calc(100% + 2px)
			right 0
			height 48px
			padding 0 80px
			flex space-between center
			.arrow
				width 48px
				height 48px
				cursor pointer
				flex center
				&.disabled
					opacity 0.25
				&:active
					transform translateY(2px)
				&:before
					content ''
					width 0
					height 0
					border-style solid
				&.prev:before
					border-width 14px 20px 14px 0
					border-color transparent #fff transparent transparent
				&.next:before
					border-width 14px 0 14px 20px
					border-color transparent transparent transparent #fff


</style>
