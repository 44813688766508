<template>
	<div class="modal-reset-password">
		<div class="bg" v-tap="close"></div>
		<div class="content">
			<div class="title">Réinitialiser un mot de passe</div>
			<div class="text strong">Voulez-vous réinitialiser le mot de passe de ce compte de jeu?</div>
			<div class="text">Vous devrez lui transmettre le nouveau mot de passe temporaire qui vous sera donné pour que l’élève puisse accéder à son compte.</div>
			<div class="buttons">
				<ui-button color="red" v-tap="close">Annuler</ui-button>
				<ui-button color="green" @click="resetPassword">Réinitialiser le mot de passe</ui-button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		modal: { type: Object, required: true }
	},
	emits: [ 'close' ],
	computed: {
		...mapState(useGroupStore, ['currentGroupDisplayId', 'currentGroupDisplay', 'selectedMemberId']),

		students() {
			return this.currentGroupDisplay.memberships.data
		},
		currentGroupId() {
			return this.currentGroupDisplayId
		},
	},
	methods: {
		...mapActions(useGroupStore, ['resetMemberPassword']),
		...mapActions(useAppStore, [ 'addModal' ]),

		close() {
			this.$emit('close')
		}, 
		resetPassword() {
			const groupId = this.currentGroupId
			const memberId = this.selectedMemberId
			this.resetMemberPassword(groupId, memberId)
			this.addModal({ slug: 'new-password', background: 'dark-blue'})
			this.close()
		}
	}
}
</script>

<style lang="stylus" scoped>

.modal-reset-password
	.bg
		absolute 0
	> .content
		max-width 500px
		padding 32px
		flex center column
		gap 32px
		background-color #eee
		border-radius 16px
		color dark
		font-size 1.6rem
		text-align center
		.title
			width 100%
			padding-bottom 16px
			border-bottom 3px solid blue
			font-size 2.4rem
			line-height 1.2em
			font-weight 500
			text-transform uppercase
		
		.strong
			font-size 1.8rem
			font-weight 500
			width calc(100% - 48px)

	.buttons
		flex center
		gap 16px
</style>