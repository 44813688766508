import { Howl } from 'howler'

const rawFiles = Object.assign(
	import.meta.glob('../assets/musics/*.mp3', { eager: true }),
	import.meta.glob('../assets/musics/*.mp4', { eager: true })
)
let _musics = []

export const initMusics = ()  => {
	_musics = Object.entries(rawFiles).map(m => {
		const file = m[1].default
		const splits = file.split('/')
		const slug = (import.meta.env.DEV ? splits[splits.length - 1] : splits[splits.length - 1].split('-').slice(0, -1).join('-')).replace('.mp3', '').replace('.mp4', '')
		return {
			slug,
			file,
			howl: new Howl({
				src: [ file ],
				volume: 1
			})
		}
	})
	console.info('🎵 INIT MUSICS (' + _musics.length + ')')
}

export const playMusic = ({ slug }) => {
	const music = _musics.find(s => s.slug === slug)
	if (music && !music.howl.playing()) {
		stopAllMusics()

		music.howl.loop(true)
		music.howl.play()
	}
}

export const stopMusic = (music) => {
	if (music.slug) {
		if (!music?.howl) music = _musics.find(m => m.slug === music.slug)
		if (music?.howl) {
			music.howl.stop()
		}
	}
}

export const stopAllMusics = () => {
	_musics
		.forEach(m => stopMusic(m))
}

export const changeMusicsVolume = ({ volume }) => {
	_musics.forEach(music => {
		music.howl.volume(volume)
	})
}

export const muteAllMusics = () => {
	_musics.forEach(music => {
		music.howl.mute(true)
	})
}

export const unmuteAllMusics = () => {
	_musics.forEach(music => {
		music.howl.mute(false)
	})
}
