import { cookieBanner } from '@/requests/home-requests'

export function useCookieBanner() {
	const userCookieAnswer = localStorage.getItem('cookie-banner')
	const googleAnalyticsId = 'G-K0DZMMV5G2'
	const showCookieBanner = ref(false)
	const shouldShowCookieBanner = !userCookieAnswer
	const text = ref()
	
	const setCookieBannerAnswerAndCloseBanner = (userAnswer) => {
		showCookieBanner.value = false 
		localStorage.setItem('cookie-banner', userAnswer ? 'accepted' : 'declined')
	}
	
	const accept = () => {
		setCookieBannerAnswerAndCloseBanner(true)
		addAnalytics(googleAnalyticsId)
	}

	const decline = () => setCookieBannerAnswerAndCloseBanner(false)

	if (userCookieAnswer === 'accepted') addAnalytics(googleAnalyticsId)
	
	if (shouldShowCookieBanner) {
		setTimeout(async () => {
			const cookieBannerContent = await cookieBanner() 
			text.value = cookieBannerContent?.html
			showCookieBanner.value = !!text.value			
		})
	}
	return { showCookieBanner, accept, decline, tapText, text }
}

const addAnalytics = (googleAnalyticsId) => {
	if (import.meta.env.PROD) {
		const head = document.getElementsByTagName('head')[0]
		const script = document.createElement('script')
		script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=' + googleAnalyticsId)
		script.onload = function() {
			window.dataLayer = window.dataLayer || []
			function gtag(){ dataLayer.push(arguments) }
			gtag('js', new Date())
			gtag('config', googleAnalyticsId)
			head.insertBefore(script, head.children[1])
		}()
	}
}

const tapText = (e) => {
	if (e.target.nodeName === 'A') {
		window.open('https://affordance-terms-and-conditions.s3.amazonaws.com/Politique_Lio.pdf', '_blank')
	}
}