import {differenceWith, get, isEqual, unionWith, uniqWith} from 'lodash'
import {updateTutorial} from '@/requests/tuto-requests'

const multipleIncludes = parameters => o => {
	return parameters
		.reduce((a, parameter) => {
			const {slug, tag} = parameter
			
			let result
			
			if (tag) result = !o.tags.includes(tag)
			if (slug) result = o.slug !== slug
			
			return result ? false : a
		}, true)
}

export const tutorialService = ({
	appStore,
	mapStore,
	tutoStore,
	userStore,
}) => {
	let parametersQueue = []
	
	const select = parameters => (tutoStore?.tutos ?? []).filter(multipleIncludes(parameters))
	
	const selectOne = parameters => select(parameters).shift()
	
	const verifyTag = (disabledTag, tags) => {
		const {difficulty, tag} = disabledTag
		const isTagExist = tags.includes(tag)
		return difficulty
			? isTagExist && difficulty === userStore?.alternative
			: isTagExist
	}
	
	const isDisabled = tuto => {
		const disablingByTags = userStore?.userPreferences?.tutorials?.disablingByTags ?? []
		const {tags} = tuto
		
		if (!disablingByTags || !tags?.length) return false
		
		return disablingByTags
			.some(criteria =>
				criteria
					.every(disabledTag => verifyTag(disabledTag, tags))
			)
	}
	
	const isReplaying = tuto => {
		return tuto?.status === 'replay'
	}
	
	const replay = ({parameters}) => {
		const tutos = select(parameters)
		
		for (let tuto of tutos) {
			tuto.status = 'replay'
		}
	}
	
	const toggleEnabling = ({criteriaArray, isEnabled}) => {
		let getValue = {
			itinerary: () => ({
				tag: `itinerary-${mapStore?.currentCategoryDetails?.slug}`,
			}),
		}
		
		let complementValue = {
			tag: value => {
				return {
					'each-difficulty': {
						tag: value,
						difficulty: userStore?.alternative,
					}
				}[value] ?? {tag: value}
			}
		}
		
		let criteria = [
			...criteriaArray
				/**
				 * Get value from current values when criteria has been specified without value
				 */
				.map(criteria => {
					const [property, value] = Object.entries(criteria).shift()
					return getValue[property] && !value
						? getValue[property]()
						: {[property]: value}
				})
				/**
				 * Add some properties when needed
				 */
				.map(criteria => {
					const [property, value] = Object.entries(criteria).shift()
					return complementValue[property]
						? complementValue[property](value)
						: {[property]: value}
				})
		]
		let disablingByTags = get(userStore, 'preference.tutorials.disablingByTags') ?? []
		let value =
			isEnabled
				? differenceWith(disablingByTags, criteria, isEqual)
				: unionWith(disablingByTags, criteria, isEqual)
	
		if (value.length) {
			userStore.augmentPreferences({
				path: 'tutorials.disablingByTags',
				value,
			})
		}
	}
	
	const isViewed = ({
		isSameItinerary = true,
		parameters,
		tuto,
	}) => {
		let isViewed = false
		let tutorial = tuto
		
		if (parameters) {
			let p = sameItinerary({isSameItinerary, parameters})
			tutorial = selectOne(p)
		}
		
		if (tutorial) isViewed = tutorial?.status === 'completed'

		return isViewed
	}
	
	const playWithSlug = ({slug}) => {
		if (!slug) return
		
		const parameters = [{slug}]
		
		playWithParameters({parameters})
	}
	
	const sameItinerary = ({parameters}) => {
		const isSlug =
			parameters
				.some(x => Object.keys(x).shift() === 'slug')
		
		if (isSlug) return parameters
	
		const itineraryName = mapStore?.currentCategoryDetails?.slug
		
		if (itineraryName) return uniqWith([...parameters, {tag: `itinerary-${itineraryName}`}], isEqual)
		
		return parameters
	}
	
	const isDifficultyRestricted = ({tuto}) => tuto?.tags?.includes('each-difficulty')
	
	const playWithParameters = ({
		force = false,
		gameResult,
		isSameItinerary = true,
		parameters,
	}) => {
		if (!parameters) return
		
		if (tutoStore.currentTuto) {
			let p =
				isSameItinerary
					? sameItinerary({parameters})
					: parameters
			const tuto = selectOne(p)

			/**
			 * ensure that the tuto exists
			 */
			if (tuto) {
				/**
				 * ensure not taking the tuto twice
				 */
				const isInQueue = parametersQueue.find(({parameters:p}) => {
					return parameters.every(({tag}) => {
						return tag === p?.tag
					})
				})

				if (!isInQueue) {
					parametersQueue.push({force, gameResult, isSameItinerary, parameters})
				}
			}
			return
		}
		
		let p =
			isSameItinerary
				? sameItinerary({parameters})
				: parameters
		const tuto = selectOne(p)

		if (tuto) {
			if (force || isReplaying(tuto)) {
				tutoStore.setCurrentTuto({ ...tuto, gameResult})
				return
			}
			if (isDisabled(tuto)) return
			if (!isDifficultyRestricted({tuto}) && isViewed({tuto})) return

			tutoStore.setCurrentTuto({ ...tuto, gameResult})
		}
	}
	
	const end = async tuto => {
		if (!tuto?.id || !tutoStore) return

		const store = tutoStore

		store.setCurrentTuto(null)

		if (!store?.tutos?.length) return
		
		if (store.tutos.find(t => t.id === tuto.id)) {
			const response = await updateTutorial({ id: tuto.id, status: 'completed' })

			if (!response?.length) return

			const currentTuto = response.find(x => x.id === tuto.id)

			if (!currentTuto) return

			store.tutos =
				store.tutos
					.map(x =>
						x.id === tuto.id
							? currentTuto
							: x)
		}
		
		const isEachDifficulty = tuto?.tags.includes('each-difficulty')

		if (isEachDifficulty) {
			const tags = tuto?.tags
			if (tags?.length) toggleEnabling({criteriaArray: tags.map(tag => ({tag})), isEnabled: false})
		}
		
		let tutoParameters = {}
		
		if (tuto?.details?.onEnd) {

			if (tuto.details.onEnd.modal) {
				const {params} = tuto.details.onEnd.modal

				if (appStore.addModal) appStore.addModal(params)

				return
			}

			if (tuto.details.onEnd.tuto) {
				tutoParameters.parameters = [{
					slug: tuto.details.onEnd.tuto,
					force: tuto.details.onEnd.force || false
				}]
			}
		} else if (parametersQueue?.length) {
			tutoParameters = parametersQueue.shift()
		}
		
		if (tutoParameters.parameters) playWithParameters(tutoParameters)
	}
	
	return {
		end,
		isViewed,
		playWithParameters,
		playWithSlug,
		replay,
		selectOne,
		toggleEnabling,
	}
}
