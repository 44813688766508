<template>
	<div class="modal-new-password">
		<div class="bg" v-tap="close"></div>
		<div class="content">
			<div class="title">Réinitialiser un mot de passe</div>
			<div class="text uppercase">Le mot de passe est réinitialisé avec succès!</div>
			<div class="text">Voici le nouveau mot de passe temporaire:</div>
			<div class="text code" @click.stop="copyToClipboard(resetPasswordMessage)">{{ resetPasswordMessage }}</div>
			<ui-button class="close-btn" color="blue" v-tap="close">Fermer</ui-button>
		</div>
	</div>
</template>

<script>
export default {
	emits: [ 'close' ],
	computed: {
		...mapState(useGroupStore, ['resetPasswordResponse']),
		resetPasswordMessage() {
			return this.resetPasswordResponse || ''
		}
	},
	methods: {
		close() {
			this.$emit('close')
		}, 
		copyToClipboard(text) {
			navigator.clipboard.writeText(text)
		}
	}
}
</script>

<style lang="stylus" scoped>
	.modal-new-password
		.bg
			absolute 0
		> .content
			max-width 500px
			padding 32px
			flex center column
			gap 16px
			background-color #eee
			border-radius 16px
			color dark
			text-align center
			.title
				width 100%
				padding-bottom 16px
				border-bottom 3px solid blue
				font-size 2.4rem
				line-height 1.2em
				font-weight 500
				text-transform uppercase
			.uppercase
				text-transform uppercase
				font-size 1.8rem
				font-weight 500
			.code
				font-size 3rem
				user-select text
</style>