<template>
	<div class="modal-delete-student">

		<div class="title">Supprimer un compte de jeu</div>

		<div class="content">
			<div class="text">Voulez-vous supprimer le compte de
				<span class="user">{{ selectedMemberUsername }}?</span>
			</div>
			<div class="text">Toute sa progression dans le jeu sera perdue.</div>
		</div>

		<div class="btn-wrap">
			<ui-button class="ui-btn" color="red" v-tap="close">Annuler</ui-button>
			<ui-button class="ui-btn" color="green" @click="deleteStudent()">Supprimer ce compte</ui-button>
		</div>
	</div>
</template>

<script>
export default {
	emits: [ 'close' ],
	computed: {
		...mapState(useGroupStore, ['selectedMemberUsername']),
	},
	methods: {
		...mapActions(useGroupStore, ['deleteCurrentMember']),
		close() {
			this.$emit('close')
		},
		async deleteStudent() {
			await this.deleteCurrentMember()
			await this.close()
		},
	},
}
</script>

<style lang="stylus" scoped>
.user
	font-weight bold

.modal-delete-student
	max-width 500px
	background-color #fff
	padding 16px 24px 24px 24px
	border-radius 16px
	display flex
	flex-direction column
	gap 32px
	text-align center
	.title
		border-bottom 2px solid #3B4851
		padding-bottom 8px
		font-size 2rem
		font-weight 500
		text-transform uppercase
		text-align center
	.content
		display flex
		flex-direction column
		gap 16px
		font-size 1.6rem
		padding-bottom 8px
		.text
			width calc(100% - 48px)
			margin 0 auto
			font-weight 500
			text-wrap balance
	.btn-wrap
		display flex
		gap 16px
		.ui-btn
			height auto
			padding 8px
			width calc(100% - 48px)
			margin 0 auto
			font-size 1.8rem
</style>
