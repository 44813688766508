<template>
	<div class="modal-delete-journal">

		<div class="title">Supprimer un compte de jeu</div>

		<div class="content">
			<div class="text">Êtes vous sûr de vouloir supprimer le compte de 
				<span class="user">{{ selectedMemberUsername }}?</span>
			</div>
			<div class="text">Toute sa progression dans le jeu sera perdue.</div>
		</div>
		
		<div class="btn-wrap">
			<ui-button class="ui-btn" color="red" v-tap="close">Annuler</ui-button>
			<ui-button class="ui-btn" color="green" @click="deleteStudent()">Supprimer ce compte</ui-button>
		</div>
	</div>
</template>

<script>
export default {
	emits: ['close'],
	computed: {
		...mapState(useGroupStore, ['selectedMemberUsername', 'selectedMemberId','currentGroupDisplay']),
		currentClass() {
			return this.currentGroupDisplay?.memberships?.data || []
		},
	},
	methods: {
		...mapActions(useGroupStore, ['deleteCurrentMember', 'setSelectedMemberId']),
		close() {
			this.$emit('close')
		},
		async deleteStudent() {
			await this.deleteCurrentMember()	
			await this.close()
			const currentIndex = this.currentClass.findIndex(student => student?.clientUser?.id === this.selectedMemberId)
			let nextIndex = (currentIndex + 1) % this.currentClass?.length
			if (this.currentClass.length <= 1) {
				this.$router.push({ name: 'class-list', params: { groupCode: this.currentGroupDisplay?.code }})
			}
			const nextStudentId = this.currentClass[nextIndex]?.clientUser?.id || this.currentClass[0]?.clientUser?.id
			await this.deleteCurrentMember()
			await this.setSelectedMemberId(nextStudentId)
			this.$router.push({ name: 'student', params: { groupCode: this.currentGroupDisplay?.code, student: nextStudentId }})
		},
	},
}
</script>

<style lang="stylus" scoped>
	.modal-delete-journal
		max-width 500px
		background-color #fff
		padding 16px 24px 24px 24px
		border-radius 16px
		display flex
		flex-direction column
		gap 32px
		text-align center
		.title
			border-bottom 2px solid #3B4851
			padding-bottom 8px
			font-size 2rem
			font-weight 500
			text-transform uppercase
			text-align center
		.content
			display flex
			flex-direction column
			gap 16px
			font-size 1.6rem
			padding-bottom 8px
			.text
				width calc(100% - 48px)
				margin 0 auto
				text-transform uppercase
				font-weight 500
				text-wrap balance
		.btn-wrap
			display flex
			gap 16px
			.ui-btn
				height auto
				padding 8px
				width calc(100% - 48px)
				margin 0 auto
				font-size 1.8rem

</style>