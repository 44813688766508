<template>
	<span class="ui-loader" :class="color" :style="{ width: size + 'px', height: size + 'px' }">
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="48px" height="48px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
			<path fill="currentColor" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
				<animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/>
			</path>
		</svg>
	</span>
</template>

<script>
export default {
	props: {
		color: { default: 'light' },
		size: { default: 48 }
	}
}
</script>

<style lang="stylus" scoped>

.ui-loader
	display inline-block
	z-index 2
	&.light
		color currentColor
	&.dark
		color #111
	svg
		width 100% !important
		height 100% !important
	
</style>
