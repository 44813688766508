<template>
	<div class="tuto">

		<!-- Div non visible. S'il y a plusieurs spritesheets, on les ajoute au DOM pour les charger et éviter un lag au moment du changement de perso -->
		<div v-if="preloadedCharacterIllustrations.length > 1" class="preload-characters">
			<img v-for="img in preloadedCharacterIllustrations" :key="img.id" :src="img.base64">
		</div>

		<div v-if="highlight && highlightStyle" class="highlight">
			<div :data-id-tuto="currentStepSettings.highlightTap ? 'next-btn' : ''" class="hole" :class="{ tappable: currentStepSettings.highlightTap }" :style="highlightStyle" v-tap="tapHighlight"></div>
		</div>

		<transition name="character-talk" appear>
			<character-talk :bubble-key="currentRoute + '-' + index"
				:character-tags="[ currentStepSettings?.character || currentTuto.character || 'character-tuto' ]"
				:text="currentText"
				:voice="currentVoice"
				:prev-button="prevButton"
				:next-button="nextButton"
				:answers="currentStep.answers || []"
				:settings="currentStepSettings"
				:game-result="tuto.gameResult"
				:transparent="highlight && highlightStyle && currentStepSettings.highlightTap"
				:skippable="skippable"
				@answer="answer"
				@prev="prev"
				@next="next"
				@skip="skip"
			/>
		</transition>
		<div class="settings-btn" :class="{ bottom : isAudioHighlighted }" v-tap="() => addModal({ slug: 'sound-settings', background: 'dark-blue', scrollable: false })">
			<ui-icon name="audio-on" :size="24" />
		</div>
		
	</div>
</template>

<script>
import { medias } from '@affordancestudio/engage-game'
import { useMapStore } from '@/stores/map-store'

export default {
	inject: ['tutorialService'],
	data() {
		return {
			loadingNext: false,
			currentRoute: 'default',
			index: 0,
			highlight: null
		}
	},
	props: {
		tuto: { default: null }
	},
	computed: {
		...mapState(useUserStore, [ 'user' ]),
		...mapState(useMapStore, ['currentCategoryDetails']),
		...mapWritableState(useUserStore, [ 'userJournal' ]),
		currentTuto() {
			return this.tuto?.details
		},
		isAudioHighlighted() {
			return this.currentStepSettings?.highlight === '#city-user-bar #settings-button'
		},
		currentStep() {
			return this.index > -1 && this.currentTuto.routes[this.currentRoute].length > this.index ?
				{ ...this.currentTuto.routes[this.currentRoute][this.index], index: this.index } :
				null
		},
		currentText() {
			const {text} = this.currentStep ?? {}
			if (!text) return ''
			return text.replaceAll('[name]', this?.user?.firstName ?? '')
		},
		currentVoice() {
			return this.currentStep?.voice
		},
		currentStepSettings() {
			const defaultSettings = {
				background: 'opacity',
				prevButton: true,
				nextButton: "",
				bubbleFrom: 'main-character',
				highlight: null,
				highlightTap: null,
				delay: 0,
				bubblePositionY: 'middle'
			}
			return { ...defaultSettings, ...this.currentTuto.defaultSettings, ...this.currentStep.settings }
		},
		isFirstStep() {
			return this.index === 0
		},
		isLastStep() {
			return this.index === this.currentTuto.routes[this.currentRoute].length - 1 && !this.currentStepSettings.goto
		},
		prevButton() {
			return !this.isFirstStep ? this.currentStepSettings.prevButton : null
		},
		nextButton() {
			return this.currentStepSettings.nextButton
		},
		highlightStyle() {
			if (this.currentStepSettings?.highlight && this.highlight) {
				const border = 16
				return {
					left: (this.highlight.x - border) + 'px',
					top: (this.highlight.y - border) + 'px',
					width: (this.highlight.width + border * 2) + 'px',
					height: (this.highlight.height + border * 2) + 'px'
				}
			}
			return {}
		},
		skippable() {
			return !this.tuto?.tags?.includes('is-tuto')
		},
		preloadedCharacterIllustrations() {
			let slugs = []
			if (this.currentTuto?.character) slugs.push(this.currentTuto.character)
			slugs = [ ...new Set([ ...slugs, ...this.currentTuto.routes.default.filter(r => r?.settings?.character).map(s => s.settings.character) ]) ]

			return slugs.map(s => medias.findAllByTags({ tags: [ 'character', 'spritesheet', s ] })?.[0] || null).filter(m => m?.id && m?.base64)
		},
	},
	watch: {
		currentRoute() {
			this.index = 0
		},
		index: {
			handler() {
				if (this.currentStepSettings?.highlight) {
					const el = document.querySelector(this.currentStepSettings.highlight)
					if (el) this.highlight = el.getBoundingClientRect()
				} else
					this.highlight = null

				if (this.currentStepSettings?.autoTapOnHighlight) this.tapHighlight()
			},
			immediate: true
		}
	},
	methods: {
		...mapActions(useAppStore, [ 'addModal' ]),
		async tapHighlight() {
			if (this.currentStepSettings.highlightTap) {
				const el = document.querySelector(this.currentStepSettings.highlightTap)

				if (el && el.hammer && el.hammer.handlers && el.hammer.handlers.tap) {
					el.hammer.handlers.tap[0]({ srcEvent: {} })
				}

				const split = this.currentStepSettings.highlightTap.split('.')
				const itineraryName = this.currentCategoryDetails?.slug
				const parameters = [
					{tag: `itinerary-${itineraryName}`},
					{tag: 'area-ontario'},
					{tag: 'onboarding'},
				]
				const slug =
					split.includes('area') && split.includes('ontario')
						? this.tutorialService.selectOne(parameters)?.slug
						: null

				await this.next(slug)
			}
		},
		async skip() {
			await this.endTuto()
		},
		async doAction(action) {
			if (action.delayBefore) await wait(action.delayBefore)
			if (action.action) await this[action.action](action?.params)
			if (action.delayAfter) await wait(action.delayAfter)
		},
		async prev() {
			this.loadingNext = true

			if (this.currentStep.onPrev) {
				await this.doAction(this.currentStep.onPrev)
			}

			this.index--
			this.loadingNext = false
		},
		async next(slug) {
			this.loadingNext = true

			if (this.currentStepSettings.goto) {
				this.currentRoute = this.currentStepSettings.goto
			}
			else {
				if (this.currentStep.onNext) {
					await this.doAction(this.currentStep.onNext)
				}
				if (this.isLastStep) {
					await this.endTuto()

					if (slug) {
						let parameters = [
							{slug}
						]
						this.tutorialService.playWithParameters({parameters})
					}
				} else {
					this.index++
				}
			}

			this.loadingNext = false
		},
		async answer(answer) {
			this.loadingNext = true

			if (answer.goto) {
				this.currentRoute = answer.goto
			} else {
				this.index++
			}

			this.loadingNext = false
		},

		mapZoomIn(params = {}) {
			const {selectors} = params
			if (!selectors) return
			const el = document.querySelector(selectors)
			if (el && el.hammer && el.hammer.handlers && el.hammer.handlers.tap) {
				el.hammer.handlers.tap[0]({ srcEvent: {} })
			}
		},

		mapZoomOut() {
			const el = document.querySelector('#zoom-out-button')
			if (el && el.hammer && el.hammer.handlers && el.hammer.handlers.tap) {
				el.hammer.handlers.tap[0]({ srcEvent: {} })
			}
		},
		toggleUserJournalTab() {
			this.userJournal.tab = "memories"
		},
		closeUserJournal() {
			this.userJournal.visible = false
		},
		openUserJournalBookmarkPage(){
			this.userJournal.visible = true
			this.userJournal.tab = "memories"
			this.userJournal.performAction = 'showTheLastPage'
		},
		async endTuto() {
			if (!this.tuto?.id) return

			await this.tutorialService.end(this.tuto)
		},
		skipTuto() {
			this.endTuto()
		},
	}
}
</script>

<style lang="stylus" scoped>

#mobile
	.tuto .settings-btn
		left 8px
		top 8px
		&.bottom
			top unset
			bottom 8px
			left 12px

.tuto
	absolute 0
	z-index 3
	&.tuto-leave-active
		transition 0.25s easeInQuart
		::v-deep(.character-talk) .character-talk-content
			transition 0.25s easeInQuart
	&.tuto-leave-to
		opacity 0
		::v-deep(.character-talk) .character-talk-content
			transform translate(0, 16px)

	.preload-characters
		absolute 0
		opacity 0.01

	.highlight
		absolute 0
		.hole
			position absolute
			box-shadow 0 0 0 9999px alpha(#25495F, 80%)
			transition 0.5s easeOutQuart
			border-radius 8px
			cursor default
			&.tappable
				cursor pointer

	.settings-btn
		position fixed
		left 28px
		bottom 8px
		cursor pointer
		width 32px
		height 32px
		flex center

</style>
