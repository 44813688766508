<template>
	<div class="modal-open-deck" @click.stop>
		<div class="bg" v-tap="close"></div>
		<div class="deck">
			<h2 class="title">SÉLECTIONNER UN TIMBRE À AJOUTER À VOTRE IDENTIFIANT LORS DE LA CONNEXION.</h2>
			<ui-scroll>
				<div class="cards-container">
					<div v-for="card in cards" :key="card" class="card" :class="getClass(card)" v-tap="() => selectCard(card)">
						<img :src="card.img" alt="card.label" />
						<label>{{card.label}}</label>
					</div>
				</div>
			</ui-scroll>
			<div class="footer">
				<ui-button class="btn" :class="shouldShowSubmit()" v-tap="submit">Sélectionner</ui-button>
			</div>
		</div>
	</div>
</template>

<script>
import UiButton from '../ui/ui-button.vue'
import uiScroll from '../ui/ui-scroll.vue'
export default {
  components: { uiScroll, UiButton },
	props: {
		modal: { default: null, required: true }
	},
	computed: {
		cards() {
			return this.modal?.cards
		},
	},
	methods: {
		close() {
			this.$emit('close')
		},
		selectCard(card){
			this.modal.selectedCard = card
		},
		submit(){
			if(this.modal.selectedCard == null) return

			this.$emit('selectCard', this.modal.selectedCard)
			this.close()
		},
		shouldShowSubmit(){
			return {
				transparent: !this.modal.selectedCard
			}
		},
		getClass(card){
			return {
				transparent: this.modal.selectedCard && card !== this.modal?.selectedCard
			}
		}
	},
}
</script>

<style lang="stylus" scoped>
$black = #54606C
.cards-container
	margin-top 24px
	display flex
	flex-wrap wrap
	gap 16px
	justify-content center
.deck
	border-radius 16px
	padding 16px 24px
	display flex
	flex-direction column
	.title
		color $black
		text-align center
		font-size 16px
		padding-bottom 8px
		border-bottom 1px solid $black
.footer
	width 100%
	display flex
	justify-content center
	border-top: 1px solid $black
	.btn
		&.transparent
			cursor unset
		height:  fit-content
		padding-block: 8px 
		margin-top 24px
		width 256px * 1.25
		font-size 16px
.card
	transition opacity 0.5s easeOutQuart
	max-width (100 / 6)%
	display flex
	flex-direction column
	align-items center
	cursor pointer
	img
		max-width 100%
	label
		cursor pointer
		color $black
.transparent
	opacity 0.5


#tablet-vertical
	.modal-open-deck
		.deck
			height round((669px * 1.7) / 1.5)
			width round((816px * 1.5) / 1.5)
			
#mobile
	.modal-open-deck
		.deck
			height round(816px / 2)
			width round(669px / 2)
		
			
.modal-open-deck
	absolute 0
	flex center
	> .bg
		absolute 0
	.deck
		height 87.5vh
		width 120vh
		max-width 100vw
		background-color #fff
		> .bg
			absolute 0
			background center center no-repeat
			background-size contain
		> .top-cache
			position absolute
			left 13%
			top 27.5%
			width 74%
			aspect-ratio 4.509
			background center center no-repeat
			background-size contain
			z-index 3
			pointer-events none
		> .items
			absolute 0
			

</style>