<template>
	<div class="modal-dev-menu">
		<div class="title">DEV CONSOLE</div>

		<div class="content">

			<div v-for="section in sections" :key="section.id" class="group">
				<div class="group-name">{{ section.slug }}</div>
				<div class="buttons row">
					<div v-for="game in section.clientGames" :key="game.id" class="button" v-tap="() => launchGame(game)">
						<span>{{ game.id }}</span>
					</div>
				</div>
			</div>

			<div v-if="$route.name === 'game'" class="group">
				<div class="group-name">Current game actions:</div>
				<div class="buttons row">
					<div class="button" v-tap="gameNextStep">Next step</div>
					<div class="button red" v-tap="gameQuit">Quit</div>
				</div>
			</div>

			<div class="separator"></div>

			<div class="group">
				<div class="group-name">Current user actions:</div>
				<div class="buttons">
					<div class="button" v-tap="resetUser">Reset user</div>
					<div class="button red" v-tap="deleteUser">Delete user</div>
					<div class="button red" v-tap="deleteAndAutoSignup">Delete user and auto signup<br><span class="small">(christophe@affordancestudio.com)</span></div>
				</div>
			</div>
		</div>

		<div v-show="loading" class="loading"><ui-loader :size="64" /></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: false
		}
	},
	props: {
		modal: { default: null }
	},
	computed: {
		...mapState(useAppStore, [ 'sections' ]),
	},
	methods: {
		...mapActions(useAppStore, [ 'initApp' ]),
		...mapActions(useUserStore, [ 'logout' ]),
		...mapActions(useGameStore, [ 'nextStep' ]),
		async launchGame({ id }) {
			if (!this.loading) {
				this.loading = true
				if (this.$route.name !== 'city') {
					this.$router.push({ name: 'city' })
					await wait(350)
				}
				this.$router.push({ name: 'game', params: { gameId: id } })
				this.loading = false
			}
		},
		async gameNextStep() {
			if (!this.loading) {
				this.loading = true
				await this.nextStep()
				this.loading = false
			}
		},
		async gameQuit() {
			if (!this.loading) {
				this.loading = true
				useGameStore().$reset()
				this.$router.push({ name: 'city' })
				this.loading = false
			}
		},
		async resetUser() {
			if (!this.loading) {
				this.loading = true
				const user = await resetCurrentUser()
				if (user) {
					await addAlternative({ userId: user.id, alternative: 'alt-2' })
					await this.initApp()
					this.$router.push({ name: 'city' })
				}
				this.loading = false
			}
		},
		async deleteUser() {
			if (!this.loading) {
				this.loading = true
				const response = await deleteCurrentUser()
				if (response) {
					this.logout()
					window.location.href = '/'
				}
				this.loading = false
			}
		},
		async deleteAndAutoSignup() {
			if (!this.loading) {
				this.loading = true
				await deleteCurrentUser()
				const response = await signup({ firstname: 'Toto', email: 'christophe@affordancestudio.com', password: 'toto1234' })
				if (response) {
					this.logout()
					window.location.href = '/'
				}
				this.loading = false
			}
		}
	}
}
</script>

<style lang="stylus" scoped>

.modal-dev-menu
	width calc(95vw - 64px)
	max-width 400px
	color #0F7C11
	border-radius 12px
	background-color #000
	font-size 1.6rem
	font-weight 500
	.title
		width 100%
		height 64px
		flex center
		background-color #0F7C11
		border-radius 8px 8px 0 0
		color #090909
		font-size 2.4rem
		font-weight 700
		text-align center
	.content
		padding 24px
		flex center column
		gap 24px
		.separator
			width 100%
			height 2px
			background-color #0F7C11
		.group
			width 100%
			.group-name
				margin 0 0 8px 0
				text-transform uppercase
				text-align center
			.buttons
				flex center column
				gap 8px
				&.row
					flex-direction row
					gap 8px
					.button
						width 50%
					
				.button
					width 100%
					padding 12px 16px
					border-radius 8px
					cursor pointer
					background-color #060606
					transition 0.1s easeOutQuart
					word-wrap break-word
					// box-shadow inset 0 -2px #111
					&:last-child
						margin-bottom 0
					&.red
						color #f00
						&:hover
							background-color #f00
					&:hover
						background-color #0F7C11
						color #000
					.small
						font-size 1.2rem
						font-style italic
	
	.loading
		absolute 0
		background-color alpha(#000, 90%)
		flex center
		border-radius 8px
		cursor wait
	

</style>
