<template>
	<div class="modal-event" data-id="event" @click.stop>
		<div class="bg"></div>
		<div class="container">
			<header class="w-full header">
				<a class="btn-close hidden"><img src="@/assets/img/close-dark.svg"></a>
				<h2 class="title">MISSIONS ET CONTRATS</h2>
				<a href="#" data-id="close-btn" @click="close" class="btn-close"><img src="@/assets/img/close-dark.svg"></a>
			</header>

			<hr class="horizontal-line" />
			<p class="description">Participe à la cocréation de Lio. Complète nos nouvelles missions ou soumets ta candidature pour un contrat.</p>
			<p class="mt-3 description"><strong>Date limite</strong>: 1er décembre.</p>
			<img class="img mt-description" src="@/assets/img/teacher-panel/infolettre-linktree.png" alt="InfoLettre">
			<div class="footer">
				<ui-button class="btn" color="green" :class="success" data-id="event-btn" v-tap="submit">Participer</ui-button>
			</div>
		</div>
	</div>
</template>

<script setup lang="js">
const emits = defineEmits(['close'])

function submit(){
	window.open('https://linktr.ee/lioacelf', '_blank');
}

function close(){
	emits('close')
}
</script>

<style lang="stylus" scoped>
$black = #54606C

.hidden
	visibility: hidden

.header
	display flex
	justify-content space-between
	align-items center

.w-full
	width 100%

.container
	border-radius 16px
	padding 16px 24px
	display flex
	flex-direction column
	justify-content center
	align-items center
	width 612px
	max-width 95vw !important
	background-color #fff
	
	.title
		color $black
		text-align center
		font-size 2.5rem
		padding-bottom 8px

	.img
		height  420px
		max-width 100%

	.description
		color $black
		font-size 2rem
		text-align center
		text-wrap balance
	.mt-description
		margin-top 16px

.btn-description
	color $black
	margin-top 4px
	margin-inline auto
	font-size 1.2rem
	text-align center
	width 80%


.footer
	width 100%
	display flex
	flex-wrap wrap
	justify-content center
	gap 16px
	margin-top 16px
	margin-bottom 8px
	.btn
		height  fit-content
		padding-block 8px 
		width 272px
		font-size 16px

.horizontal-line
	width 100%
	height 2px
	margin-bottom 9px
	border none
	background-color blue

.modal-event
	flex center
	> .bg
		absolute 0
</style>